@media (max-width: 666px)  {
  .new-order-suggestions-container {
    position: relative;
  }

  .new-order-customer-clear {
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    margin-top: 2px;

    height: 18px;
    width: 18px;

    outline: none;
    border: none;
    border-radius: 50px;
    background-color: #aaa;
    
    color: #fff;
    font-size: 0.875rem;
  }

  .new-order-suggestions {
    position: absolute;
    z-index: 1;
    top: 100%;
    margin-top: 4px;
    width: 100%;
    overflow: auto;

    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    border: 2px solid #D0BDE2;
    border-top: 0;
    box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.2);
  }
    .new-order-suggestion {
      width: 100%;
      height: 36px;
      padding: 0px 20px;

      outline: none;
      border: none;
      background-color: transparent;
      text-align: left;
    }  
}

@media (min-width: 666px) and (max-width: 961px) 
{
  .orders-container {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .orders {
    position: relative;
    height: calc(100% - 66px);
    margin-top: 10px;
    background-color: transparent;

    text-align: center;
  }
    .orders > h1 {
      height: 50px;
      font-size: 32px;
      font-family: 'PT Sans', sans-serif;
    }

  .orders-columns {
    z-index: 455;
    height: 50px;
    padding: 0px 20px;
    
    display: grid;
    grid-template-columns: 30px 80px auto 20% 40px;
    align-items: center;

    border-bottom: 1px solid #D0BDE2;
  }
    .orders-columns > div {
      height: 100%;

      display: flex;
      align-items: center;
      justify-content: center;

      color: #fff;
      color: #46005D;
      font-size: 24px;
      font-weight: bold;
      font-family: 'Space Mono', sans-serif;
      font-family: 'PT Sans', sans-serif;
    }
    .orders-columns > button {
      height: 40px;
      width: 40px;

      display: flex;
      align-items: center;
      justify-content: center;

      outline: none;
      border: none;
      border: 3px solid #C767E5;
      border-radius: 25px;
      background-color: #C767E5;

      color: #000;
      color: #fff;
      font-size: 24px;
      font-weight: bold;
      font-family: 'PT Sans', sans-serif;

      -webkit-box-shadow: 1px 1px 2px 1px rgba(20,20,20,0.6);
      -moz-box-shadow: 1px 1px 2px 1px rgba(20,20,20,0.6);
      box-shadow: 1px 1px 2px 1px rgba(20,20,20,0.6);
    }

  .orders-list {
    z-index: 555;
    height: calc(100% - 60px);
    
    overflow-y: scroll;
    padding: 6px 8px;

    text-align: center;  
  }
    .more-arrow {
      width: 30px;
      height: 30px;

      display: flex;
      align-items: center;
      justify-content: center;
      
      color: #000;
      color: #8c9674;
      color: #adbf5a;
      font-size: 30px;
    }
      #arrow-up {
        position: absolute;
        z-index: 777;
        top: 50px;
        left: 50%;
        transform: translateX(-50%);
      }
      #arrow-down {
        position: absolute;
        z-index: 777;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
      }


  .new-order-container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 777;
    height: 100%;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: rgba(50,50,50,0.5);
  }

  .new-order {
    position: relative;
    width: 80%;

    padding: 20px 10px;

    border: 4px solid #D0BDE2;
    border-radius: 10px;

    background-color: rgb(250,250,250);

    -webkit-box-shadow: 0px 0px 2px 3px rgba(50,50,50,0.7);
    -moz-box-shadow: 0px 0px 2px 3px rgba(50,50,50,0.7);
    box-shadow: 0px 0px 2px 3px rgba(50,50,50,0.7);  
  }
    .new-order > h2 {
      position: relative;
      margin: 0;
      margin-top: 10px;

      text-align: left;
      color: #fff;
      color: rgb(30,30,30);
      font-size: 24px;
      font-family: 'PT Sans', sans-serif;
    }
    
    .new-order-input {
      text-align: left;
      margin-top: 20px;
    }
      .new-order-input > span {
        margin-left: 2px;

        color: #000;
        font-size: 16px;
        font-family: 'PT Sans', sans-serif; 
      }
      
      .new-order-input > input {
        display: flex;
        margin-top: 6px;
        height: 30px;
        width: 60%;
        
        padding: 0px 10px;
        border: 3px solid #fff;
        border: 3px solid #D0BDE2;
        outline: none;
        border-radius: 4px;
        
        background-color: rgb(240,240,240);

        text-align: left;
        color: #000;
        font-size: 16px;
        font-family: 'Nunito', sans-serif; 

        -webkit-box-shadow: 0px 1px 1px 1px rgba(50,50,50,0.7);
        -moz-box-shadow: 0px 1px 1px 1px rgba(50,50,50,0.7);
        box-shadow: 0px 1px 1px 1px rgba(50,50,50,0.7);
      }
      
        .new-order-input > input:hover,
        .new-order-input > input:focus {
          transition-duration: 0.1s;
          border: 3px solid #C767E5;
          background-color: transparent;

          -webkit-box-shadow: 0px 1px 1px 1px rgba(50,50,50,0.7);
          -moz-box-shadow: 0px 1px 1px 1px rgba(50,50,50,0.7);
          box-shadow: 0px 1px 1px 1px rgba(50,50,50,0.7);
        }

      .new-order-submit-disabled {
        margin-top: 30px;
        height: 40px;

        display: flex;
        align-items: center;
        justify-content: center;

        border: none;
        outline: none;
        border-radius: 25px;

        background-color: #ccc;

        color: #000;
        color: #fff;
        font-size: 18px;
        font-weight: bold;
        font-family: 'Source Code Pro', monospace;
        font-family: 'Nunito', sans-serif;

        -webkit-box-shadow: 0px 2px 2px 2px rgba(50,50,50,0.7);
        -moz-box-shadow: 0px 2px 2px 2px rgba(50,50,50,0.7);
        box-shadow: 0px 2px 2px 2px rgba(50,50,50,0.7);
      }

      .new-order-submit, .new-order-submit:focus {
        cursor: pointer;

        position: absolute;
        bottom: 20px;
        right: 30px;

        height: 40px;
        width: 150px;

        display: flex;
        align-items: center;
        justify-content: center;

        border: none;
        outline: none;
        border-radius: 25px;

        background-color: #C767E5;

        color: #000;
        color: #fff;
        font-size: 18px;
        font-weight: bold;
        font-family: 'Source Code Pro', monospace;
        font-family: 'Nunito', sans-serif;

        -webkit-box-shadow: 0px 2px 2px 2px rgba(50,50,50,0.7);
        -moz-box-shadow: 0px 2px 2px 2px rgba(50,50,50,0.7);
        box-shadow: 0px 2px 2px 2px rgba(50,50,50,0.7);
      }
        
        .new-order-submit:hover {
          transition-duration: 0.1s;
          border: 3px solid #C767E5;
          color: #C767E5;
          background-color: rgb(250,250,250);
        }

        .new-order-submit:active {
          transition-duration: 0.1s;

          margin-top: 23px;
          border: 3px solid #adbf5a;
          color: #adbf5a;
          background-color: rgb(250,250,250);

          -webkit-box-shadow: 0px 0px 0px 0px rgba(50,50,50,0.7);
          -moz-box-shadow: 0px 0px 0px 0px rgba(50,50,50,0.7);
          box-shadow: 0px 0px 0px 0px rgba(50,50,50,0.7);
        }

    
    .new-order-close {
      cursor: default;
      position: absolute;
      right: 10px;
      top: 10px;

      display: flex;
      align-items: center;
      justify-content: center;

      border: none;
      border-radius: 30px;
      outline: none;

      height: 18px;
      width: 18px;

      background-color: transparent;
    }
      .new-order-close > span {
        cursor: default;
        pointer-events: none;

        color: #000;
        color: red;
        font-size: 12px;
        font-weight: bold; 
      }
      
      .new-order-close:hover {
        transition-duration: 0.1s;
        background-color: #fff;
        background-color: red;
      }
      
      .new-order-close:hover > span {
        transition-duration: 0.1s;
        color: red;
        color: #fff;
      }

  .orders-delete-order-container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 777;
    height: 100%;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: rgba(50,50,50,0.5);
  }

  .orders-delete-order {
    position: relative;
    width: 90%;

    border: 2px solid #adbf5a;
    border-radius: 10px;

    background-color: rgb(250,250,250);

    -webkit-box-shadow: 0px 0px 2px 3px rgba(50,50,50,0.7);
    -moz-box-shadow: 0px 0px 2px 3px rgba(50,50,50,0.7);
    box-shadow: 0px 0px 2px 3px rgba(50,50,50,0.7);  
  }
    .orders-delete-order > h2 {
      width: 100%;
      margin: 20px 0px;

      text-align: center;
      color: #fff;
      color: rgb(30,30,30);
      font-size: 30px;
      font-family: 'PT Sans', sans-serif;
    }
    .orders-delete-order > p {
      margin: 0px 40px;

      color: rgb(30,30,30);
      font-size: 18px;
      font-family: 'Nunito', sans-serif;
    }
      .orders-delete-order > p > span {
        font-weight: bold;   
      }

    .orders-delete-order-actions {
      margin: 20px 10px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;
    } 
    .orders-delete-order-cancel, 
    .orders-delete-order-cancel:focus {
        height: 40px;

        display: flex;
        align-items: center;
        justify-content: center;

        border: none;
        outline: none;
        border-radius: 25px;

        background-color: transparent;

        color: #000;
        font-size: 18px;
        font-weight: bold;
        font-family: 'PT Sans', sans-serif;
      }

    .orders-delete-order-submit, 
    .orders-delete-order-submit:focus {
        height: 40px;
        width: 150px;

        display: flex;
        align-items: center;
        justify-content: center;

        border: none;
        outline: none;
        border-radius: 25px;

        background-color: #adbf5a;

        color: #000;
        color: #fff;
        font-size: 18px;
        font-weight: bold;
        font-family: 'PT Sans', sans-serif;

        -webkit-box-shadow: 0px 2px 2px 2px rgba(50,50,50,0.7);
        -moz-box-shadow: 0px 2px 2px 2px rgba(50,50,50,0.7);
        box-shadow: 0px 2px 2px 2px rgba(50,50,50,0.7);
      }
}

@media (min-width: 961px)  {
  
  .new-order-container {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 200;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: rgba(0,0,0,0.6);
  }

  .new-order {
    position: relative;
    width: 600px;
    padding: 40px;
    padding-bottom: 20px;

    border-radius: 20px;

    box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.2);  
  }
    .new-order > h2 {
      margin: 0;

      text-align: left;
      color: #fff;
      color: rgb(30,30,30);
      font-size: 30px;
      font-family: 'PT Sans', sans-serif;
    }
    
    .new-order-input {
      position: relative;
      text-align: left;
      margin-top: 20px;
    }
      .new-order-input > label {
        display: block;
        color: #000;
        font-size: 18px;
        font-family: 'PT Sans', sans-serif; 
      }
      .new-order-input > input,
      .new-order-input > textarea {
        width: 100%;
        margin-top: 4px;
      }

  .new-order-suggestions-container {
    position: relative;
  }

  .new-order-customer-clear {
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    margin-top: 2px;

    height: 18px;
    width: 18px;

    outline: none;
    border: none;
    border-radius: 50px;
    background-color: #aaa;
    
    color: #fff;
    font-size: 0.875rem;
  }

  .new-order-suggestions {
    position: absolute;
    z-index: 1;
    top: 100%;
    margin-top: 4px;
    width: 100%;
    overflow: auto;

    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border: 2px solid #D0BDE2;
    box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.2);
  }
    .new-order-suggestion {
      cursor: pointer;
      width: 100%;
      height: 36px;
      padding: 0px 20px;

      outline: none;
      border: none;
      background-color: transparent;
      text-align: left;
    }  

}