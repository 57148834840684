@media (max-width: 666px) {
  .layout-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    /*overflow-y: scroll;*/
  }

  .layout {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    /*margin-top: -70px;*/
    /*height: 100%;*/
  }
}

@media (min-width: 666px) and (max-width: 961px) {
}

@media (min-width: 961px) {
  .layout-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    /*overflow-y: scroll;*/
  }

  .layout {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    /*margin-top: -70px;*/
    /*height: 100%;*/
  }
}
