@media (max-width: 666px) {
    .filters-o-statuses {
    }    
      .filters-o-status {
        display: block;
        margin-bottom: 4px;

        outline: none;
        border: none;
        background-color: transparent;

        text-align: left;
      }
        .filters-o-status i {
          color: #D0BDE2;
          margin-right: 4px;
        }
        .filters-o-status:hover i {
          color: #D0BDE2aa;
        }
        .filters-o-status.active i {
          color: #C767E5;
        }
        .filters-o-status.active:hover i {
          color: #C767E5aa;
        }

  .filters-o-date-inputs {
    height: 40px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 8px;
  }
    .filters-o-date-input {
      position: relative;
      width: 100%;
      height: 40px;
    }
      .filters-o-date {
        position: absolute;
        top: 0;
        height: 40px;
        width: 100%;
      }
      .filters-o-date-toggle {
        transition-duration: 0.3s;
        width: 100%;
        height: 40px;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        grid-gap: 8px;

        border: 1px solid #D0BDE2;
        border-radius: 30px;
        background-color: transparent;
      }
        .filters-o-date-toggle:hover {
          border: 1px solid #C767E5;
        }
        .filters-o-date-toggle > i {
          color: #8c9674;
          color: #4B6B3B;
          color: #C767E5;
          font-size: 12px;
        }
}

@media (min-width: 666px) and (max-width: 961px) 
{
  .filters-o-container {
    position: relative;
    height: 50px;
    width: calc(100% - 20px);
    top: 6px;
    left: 8px;
    
    border: 2px solid #C767E5;
    border-radius: 8px;

    background-color: rgb(250,250,250);
    background-color: #D0BDE2;
    background-color: #fff;

    overflow: hidden;
  }
    .filters-o-container.active {
      height: calc(100% - 70px);
      overflow-y: scroll;
    }

  .filters-o-actions {
    height: 50px;
    padding: 0px 8px;
    margin-bottom: 10px;

    display: grid;
    grid-template-columns: auto 30px 30px 30px;
    grid-gap: 8px;
    align-items: center;
  }

  .filters-o-reset {
    cursor: pointer;
    height: 30px;
    width: 30px;

    outline: none;
    border: 2px solid gray;
    border-radius: 50%;
    
    background-color: #fff;

    color: gray;
    font-size: 14px;
    font-weight: 800;
  }
    .filters-o-reset.active,
    .filters-o-reset:hover {
      background-color: gray;
      color: #fff;
    }

  .filters-o-menu {
    height: 30px;
    width: 30px;

    outline: none;
    border: none;
    border-radius: 50%;
    
    background-color: #C767E5;

    color: #fff;
    font-size: 14px;
    font-weight: 800;
  }

  #filters-o-sync {
    border: 2px solid #C767E5;
    background-color: #D0BDE2;
    background-color: #C767E5;
    background-color: transparent;
    background-color: #fff;
    color: #fff;
    color: #C767E5;
  }
    #filters-o-sync.active,
    #filters-o-sync:hover {
      font-size: 14px;
      background-color: #C767E5;
      color: #fff;
    }


  .filters-o-action {
    cursor: pointer;
    position: relative;
    width: 90%;
    margin-top: -2px;
    overflow: hidden;
    
    display: flex;
    align-items: center;
    justify-content: center;

    border: none;
    border: 3px solid #C767E5;
    outline: none;
    border-radius: 25px;

    background-color: #C767E5;

    color: #000;
    color: #fff;
    font-size: 22px;
    font-weight: bold;
    font-family: 'PT Sans', sans-serif;
    white-space: nowrap;

    -webkit-box-shadow: 1px 1px 2px 1px rgba(20,20,20,0.7);
    -moz-box-shadow: 1px 1px 2px 1px rgba(20,20,20,0.7);
    box-shadow: 1px 1px 2px 1px rgba(20,20,20,0.7);
  }
    .filters-o-action > span {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      
      color: #000;
      color: #fff;
      font-size: 22px;
      font-weight: bold;
      font-family: 'PT Sans', sans-serif;
    }
    #filters-o-action-progress {
      cursor: default;
      background-color: rgb(250,250,250);
    }
      #filters-o-action-progress > span {
        color: #C767E5;
      }
    .filters-o-action-progress {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;

      background-color: rgba(199,103,229,0.2);
    }

  .filters-o-section {
    margin: 0px 8px;
    padding: 10px 0px;
  }
    .filters-o-section > h2 {
      margin: 0;
      margin-bottom: 8px;
      padding-left: 10px;
      
      display: flex;
      align-items: center;

      color: #46005D;
      font-size: 20px;
      font-weight: bold;
      font-family: 'PT Sans', sans-serif;
    }
    .filters-o-section-input {
      position: relative;
      display: flex;
      flex-direction: row;
    }
      .filters-o-section-input > input {
        height: 30px;
        width: 100%;
        padding: 0px 10px;
        
        border: none;
        outline: none;
        border: 1px solid #ccc;
        border-radius: 30px;

        background-color: transparent;
        
        color: #000;
        font-size: 16px;
        font-family: 'Nunito', sans-serif;
      }   
        .filters-o-section-input > input:focus {
          border: 2px solid #C767E5;
          background-color: transparent;
        }

    .filters-o-section-option, .filters-o-section-option:focus {
      height: 30px;
      padding: 0px 10px;
      margin: 0px 2px;
      
      border: none;
      outline: none;

      border: 3px solid #adbf5a;
      border: 3px solid #8c9674;
      border-radius: 4px;

      background-color: rgb(250,250,250);
      
      color: #adbf5a;
      color: #000;
      font-size: 16px;
      font-family: 'Nunito', sans-serif;
    }   
      .filters-o-section-option:hover {
        border: 3px solid #adbf5a;
      }
      .filters-o-section-option.active {
        color: #fff;
        background-color: #adbf5a;
      }
    
    
    .filters-o-section-times, .filters-o-section-times:focus, .filters-o-section-times:active {
      cursor: default;

      position: absolute;
      top: 50%;
      right: 4px;
      transform: translateY(-50%);

      display: flex;
      align-items: center;
      justify-content: center;

      height: 16px;
      width: 16px;

      outline: none;
      border: none;
      border-radius: 50px;
      background-color: rgb(80,80,80);
    }
      .filters-o-section-times:hover {
        cursor: default;
        background-color: rgba(80,80,80,0.8);
      }
      .filters-o-section-times > span {
        cursor: default;
        pointer-events: none;
        color: #fff;
        font-size: 8px;
      }

    .filters-o-section-checkbox {
      display: flex;
      flex-direction: column;

      padding: 0px 10px;
      text-align: left;
    }
      .filters-o-section-checkbox > label {
        margin: 2px 0px;

        color: #000;
        font-size: 16px;
        font-family: 'Nunito', sans-serif;
      }



  .filters-o-dates {
    padding-bottom: 10px;
    margin: 0px 8px;

    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
  }
    .filters-o-dates > h2 {
      margin: 0;
      margin-top: 10px;
      margin-bottom: 8px;
      padding-left: 10px;

      color: #46005D;
      font-size: 20px;
      font-weight: bold;
      font-family: 'PT Sans', sans-serif;
    }

  .filters-o-dates-inputs {
    margin: 0px 10px;

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }

  .filters-o-dates-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    height: 36px;

    border: 1px solid #ccc;
    border-radius: 4px;

    background-color: rgb(250,250,250);
    background-color: transparent;

    color: #000;
    font-size: 16px;
    font-family: 'Nunito', sans-serif;
  }
    .filters-o-dates-input > span {
      margin-right: 6px;

      color: #8c9674;
      color: #4B6B3B;
      font-size: 12px;
    }
    .filters-o-dates-input > div {
      margin-top: 2px;
      margin-left: 6px;

      font-size: 12px;
    }
}

@media (min-width: 961px)  {
  .filters-o-container {
    
  }
  
    .filters-o-statuses {
    }    
      .filters-o-status {
        display: block;
        margin-bottom: 4px;

        outline: none;
        border: none;
        background-color: transparent;

        text-align: left;
      }
        .filters-o-status i {
          color: #D0BDE2;
          margin-right: 4px;
        }
        .filters-o-status:hover i {
          color: #D0BDE2aa;
        }
        .filters-o-status.active i {
          color: #C767E5;
        }
        .filters-o-status.active:hover i {
          color: #C767E5aa;
        }

  .filters-o-date-inputs {
    height: 40px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 8px;
  }
    .filters-o-date-input {
      position: relative;
      width: 100%;
      height: 40px;
    }
      .filters-o-date-input > div {
        width: 100%;
      }
      .filters-o-date {
        position: absolute;
        top: 0;
        height: 40px;
        width: 100%;
      }
      .react-datepicker-popper {
        z-index: 3;
      }
      .filters-o-date-toggle {
        transition-duration: 0.3s;
        height: 40px;
        width: 100%;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        grid-gap: 8px;

        border: 1px solid #D0BDE2;
        border-radius: 30px;
        background-color: transparent;
      }
        .filters-o-date-toggle:hover {
          border: 1px solid #C767E5;
        }
        .filters-o-date-toggle > i {
          color: #8c9674;
          color: #4B6B3B;
          color: #C767E5;
          font-size: 12px;
        }
}  

