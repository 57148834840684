@media (max-width: 666px) {
	.select-v-add {
		position: relative;
		width: 100%;
	}

	.select-v-add > button {
		height: 40px;
		padding: 0px 16px;
	}
		.selected-v-add.active {
			outline: 3px solid #C767E5;
			background-color: #fff;
		}

	.select-v-add-items {
		position: absolute;
		z-index: 999;
		left: 0;
		top: 100%;
		padding: 0;
		margin-top: 4px;
		overflow: auto;
		
		width: 100%;
		height: 140px;

		border-radius: 8px;
	  box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.2);
	}
	
	.select-v-add-item, 
	.select-v-add-item:focus, 
	.select-v-add-item:active {
		display: flex;
		align-items: center;
		justify-content: flex-start;

		height: 32px;
		width: 100%;
		padding: 0px 16px;

		border: none;
		outline: none;

		background-color: transparent;

		color: #222;
	  font-size: 0.875rem;
	  font-family: 'Nunito', sans-serif;        
	  
	  overflow: hidden;
	  text-overflow: ellipsis;
	  white-space: nowrap;
	}
		.select-v-add-item.active {
			outline: none;
			color: #fff;
			background-color: #C767E5 !important;
		}
			.select-v-add-item.active:hover {
				color: #fff;
				background-color: #C767E5;
			}
}


@media (min-width: 666px) and (max-width: 961px) 
{
	.select-v-add {
		position: relative;
		width: 100%;
	}

	.selected-v-add {
		width: calc(100% - 12px);
		height: 30px;
		padding-left: 10px;

		display: flex;
		align-items: center;
		justify-content: flex-start;
		
		border: none;
		outline: 1px solid #D0BDE2;
		border-radius: 4px;

		background-color: rgba(240,240,240);
		color: #000;
	  font-size: 16px;
	  font-family: 'PT Sans', sans-serif;        
	  font-family: 'Nunito', sans-serif;        

	  -webkit-box-shadow: 0px 1px 1px 1px rgba(50,50,50,0.7);
	  -moz-box-shadow: 0px 1px 1px 1px rgba(50,50,50,0.7);
	  box-shadow: 0px 1px 1px 1px rgba(50,50,50,0.7);
	}

		.selected-v-add.active {
			outline: 3px solid #C767E5;
			background-color: #fff;
		}

	.select-v-add-items-none {
		display: none;
	}

	.select-v-add-items {
		position: absolute;
		z-index: 999;
		left: 50%;
		bottom: 20px;
		transform: translateX(-50%);
		padding: 0;
		
		width: 110%;
		max-height: 140px;

		border: 1px solid #D0BDE2;
		border-radius: 10px;

		background-color: rgb(250,250,250);
		overflow: scroll;

		-webkit-box-shadow: 0px 1px 2px 1px rgba(50,50,50,0.7);
	  -moz-box-shadow: 0px 1px 2px 1px rgba(50,50,50,0.7);
	  box-shadow: 0px 1px 2px 1px rgba(50,50,50,0.7);
	}
	
	.select-v-add-item, 
	.select-v-add-item:focus, 
	.select-v-add-item:active {
		display: flex;
		align-items: center;
		justify-content: center;

		height: 32px;
		width: 100%;
		padding: 0px 10px;

		border: none;
		outline: none;

		background-color: transparent;

		color: #000;
	  font-size: 12px;
	  font-family: 'Nunito', sans-serif;        
	  
	  overflow: hidden;
	  text-overflow: ellipsis;
	  white-space: nowrap;
	}

		.select-v-add-item-hover, .select-v-add-item-hover:focus, .select-v-add-item-hover:active {
			display: flex;
			align-items: center;
			justify-content: center;

			height: 32px;
			width: 100%;
			padding: 0px 10px;

			list-style-type: none;
			border: none;
			outline: none;

			background-color: #D0BDE2;

			color: #000;
		  font-size: 12px;
		  font-family: 'Nunito', sans-serif;        
		  
		  overflow: hidden;
		  text-overflow: ellipsis;
		  white-space: nowrap;

		}

		.select-v-add-item.active {
			outline: none;
			color: #fff;
			background-color: #C767E5;
		}
			.select-v-add-item.active:hover {
				color: #fff;
				background-color: #C767E5;
			}
}

@media (min-width: 961px)  {
	.select-v-add {
		position: relative;
		display: flex;
		height: 36px;
		width: 100%;

	}

		.select-v-add > button {
			height: 40px;
			padding: 0px 20px;
		}

	.select-v-add-items {
		position: absolute;
		bottom: 100%;
		left: 0;
		margin-bottom: 8px;
		max-height: 140px;
		z-index: 999;

		border: 1px solid #D0BDE2;
		border-radius: 10px;
		background-color: rgb(250,250,250);
		overflow: scroll;

		box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.1);
	}
	.select-v-add-item {
		display: flex;
		align-items: center;
		justify-content: flex-start;

		height: 36px;
		width: 100%;
		padding: 0px 20px;

		border: none;
		outline: none;
		background-color: transparent;

	  overflow: hidden;
	  text-overflow: ellipsis;
	  white-space: nowrap;
	}

		.select-v-add-item.active {
			outline: none;
			color: #fff;
			background-color: #C767E5;
		}
			.select-v-add-item.active:hover {
				color: #fff;
				background-color: #C767E5;
			}
}