@media (max-width: 666px) {
  .details {
    position: relative;
    cursor: pointer;

    height: 50px;
    width: 100%;
    margin-bottom: 4px;

    border-radius: 8px;
    display: grid;
    grid-template-columns: 180px auto 240px 40px;
    grid-template-columns: 80px auto 80px 40px;
    align-items: center;
  }   
    .details > div {
/*      border-right: 1px solid #eee;*/
    }
      .details > div span {
        padding-left: 8px;
        text-align: left;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    .details-action {
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;

      outline: none;
      border: none;
      border-radius: inherit;
      background-color: transparent;
    }
      
  .details-changes {
    position: relative;
    width: 100%;
    z-index: 1;

    display: grid;
    grid-template-columns: 1fr;
    
    margin: 0px 0px;
    border-left: 1px solid #ccc;
  }
    .details-changes > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .details-changes > div > button {
      display: flex;
      align-items: center;
      justify-content: center;

      height: 16px;
      width: 16px;
      
      border: none;
      outline: none;
      border-radius: 30px;

      background-color: red;
    }
      .details-changes > div > button:hover {
        background-color: rgba(250,0,0,0.6);
      }
        .details-changes > div > button:hover > span {
          font-weight: bold
        }
    
      #details-save-changes {
        background-color: green;
      }
        #details-save-changes:hover {
          background-color: rgba(0,250,0,0.6);
        }
          #details-save-changes:hover > span {
            font-weight: bold
          }
      

      .details-changes > div > button > span {
        pointer-events: none;
        color: #fff;
        font-size: 9px; 
      }

  .details-delete {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    margin: 10px 0px;
    border-left: 1px solid #ccc;
  }
    .details-delete > button {
      display: flex;
      align-items: center;
      justify-content: center;

      height: 20px;
      width: 20px;
      
      border: none;
      outline: none;
      border-radius: 30px;
      background-color: #aaa;

      color: #fff;
      font-size: 0.75rem; 
    }
      .details-delete > button:active {
        background-color: #C767E5;
      }



  #details-open {
    border: 1px solid #D0BDE2;
  }
    #details-open > .details-attr {
        color: #444;
        font-weight: 800;
      }
  
  #details-converted {
    background-color: #D0BDE2cc;
  }
    #details-converted > .details-attr {
      color: #222;
      font-weight: 800;
    }
  
  #details-validated {
    background-color: #C767E5cc;
  }
    #details-validated:hover {
      background-color: #C767E5aa;
    }
    #details-validated > .details-attr {
      font-weight: 600;
    }
  
  #details-consolidated {
    background-color: #46005Dcc;
    color: #fff;
  }
    #details-consolidated:hover {
      background-color: #46005Daa;
    }
    #details-consolidated > .details-attr {
      color: #fff;
      font-weight: 800;
    }
}


@media (min-width: 666px) and (max-width: 961px) 
{
  .details {
    cursor: pointer;
    width: 100%;
    height: 40px;
    margin-bottom: 4px;

    display: grid;
    grid-template-columns: 30px 80px calc(100% - 170px) 30px 30px;
    align-items: center;

    outline: none;
    border: none;
    border-radius: 5px;
  }

    #details-open {
      background-color: #F0F0F0;
    }
      #details-open > .details-attr {
        color: #444;
        font-weight: bold;
      }
    #details-converted {
      background-color: #D0BDE2;
    }
      #details-converted > .details-attr {
        color: #444;
        font-weight: bold;
      }
    #details-validated {
      background-color: #C767E5;
    }
      #details-validated > .details-attr {
        color: #fff;
        font-weight: bold;
      }
    #details-consolidated {
      background-color: #46005D;
      color: #fff;
    }
      #details-consolidated > .details-attr {
        color: #fff;
        font-weight: bold;
      }
      
      
    .details-attr, .details-attr:focus {    
      height: 100%;
      background-color: green;

      display: flex;
      align-items: center;

      outline: none;
      border: none;
      border-right: 1px solid #ccc;

      background-color: transparent;

      font-size: 13px;
      font-family: 'Nunito', sans-serif;
      
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow-x: scroll;
    }

    .details-delete {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .details-delete > button {
      display: flex;
      align-items: center;
      justify-content: center;

      height: 16px;
      width: 16px;

      outline: none;
      border: none;
      border-radius: 30px;

      background-color: transparent;
    }
    
      .details-delete > button > span {
        pointer-events: none;
        font-size: 12px;
      }
      
      .details-delete > button:hover {
        background-color: rgb(250,250,250);
        background-color: red;
      }

      .details-delete > button:hover > span {
        pointer-events: none;
      }
      
  .details-changes {
    width: 100%;

    display: grid;
    grid-template-columns: 1fr;
    
    margin: 0px 0px;
    border-left: 1px solid #ccc;
  }
    .details-changes > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .details-changes > div > button {
      display: flex;
      align-items: center;
      justify-content: center;

      height: 16px;
      width: 16px;
      
      border: none;
      outline: none;
      border-radius: 30px;

      background-color: red;
    }
      #details-save-changes {
        background-color: green;
      } 
      .details-changes > div > button > span {
        pointer-events: none;
        color: #fff;
        font-size: 9px; 
      }

  .details-delete {
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    border-left: 1px solid #ccc;
  }

    .details-delete > button {
      display: flex;
      align-items: center;
      justify-content: center;

      height: 20px;
      width: 20px;
      
      border: none;
      outline: none;
      border-radius: 30px;

      background-color: gray;
    }

      .details-delete > button > span {
        pointer-events: none;
        color: #fff;
        font-size: 11px; 
      }
}

@media (min-width: 961px)  {
  .details {
    position: relative;
    cursor: pointer;
    
    width: 100%;
    margin-bottom: 4px;
    padding: 8px;

    border-radius: 8px;
    display: grid;
    grid-template-columns: 180px auto 240px 40px;
    align-items: center;
  }   
    .details > div {
/*      border-right: 1px solid #eee;*/
    }
      .details > div span {
        padding-left: 8px;
        text-align: left;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        font-weight: 600;
      }
    .details-action {
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;

      outline: none;
      border: none;
      border-radius: inherit;
      background-color: transparent;
    }
      
  .details-changes {
    position: relative;
    width: 100%;
    z-index: 1;

    display: grid;
    grid-template-columns: 1fr;
    
    margin: 0px 0px;
    border-left: 1px solid #ccc;
  }
    .details-changes > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .details-changes > div > button {
      display: flex;
      align-items: center;
      justify-content: center;

      height: 16px;
      width: 16px;
      
      border: none;
      outline: none;
      border-radius: 30px;

      background-color: red;
    }
      .details-changes > div > button:hover {
        background-color: rgba(250,0,0,0.6);
      }
        .details-changes > div > button:hover > span {
          font-weight: bold
        }
    
      #details-save-changes {
        background-color: green;
      }
        #details-save-changes:hover {
          background-color: rgba(0,250,0,0.6);
        }
          #details-save-changes:hover > span {
            font-weight: bold
          }
      

      .details-changes > div > button > span {
        pointer-events: none;
        color: #fff;
        font-size: 9px; 
      }

  .details-delete {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    margin: 10px 0px;
    border-left: 1px solid #ccc;
  }
    .details-delete > button {
      pointer-events: auto;
      display: flex;
      align-items: center;
      justify-content: center;

      height: 18px;
      width: 18px;
      
      border: none;
      outline: none;
      border-radius: 30px;
      background-color: gray;

      color: #fff;
      font-size: 0.875rem; 
    }



  #details-open {
    pointer-events: none;
    border: 1px solid #D0BDE2;
  }
    #details-open > .details-attr {
        color: #444;
        font-weight: 800;
      }
  
  #details-converted {
    background-color: #D0BDE2cc;
  }
    #details-converted > .details-attr {
      color: #222;
    }
  
  #details-validated {
    background-color: #C767E5cc;
  }
    #details-validated:hover {
      background-color: #C767E5aa;
    }
    #details-validated > .details-attr {
      color: #fff;
    }
  
  #details-consolidated {
    background-color: #46005Dcc;
    color: #fff;
  }
    #details-consolidated:hover {
      background-color: #46005Daa;
    }
    #details-consolidated > .details-attr {
      color: #fff;
    }
}