@media (max-width: 666px) {
  .home {
    position: relative;
    width: 100vw;
    height: 100vh;
  }

  .home-content {
    width: 100%;
    height: calc(100% - 70px);
  }

  .submenu {
    position: absolute;
    top: 72px;
    left: 0;
    z-index: 222;
    
    width: 100vw;
    display: flex;
    flex-direction: column;
    
    border-bottom-left-radius: 32px;
    border-bottom-right-radius: 32px;
    background-color: #46005D;
    background-color: #F0F0F0;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
  }
    .submenu-item { 
      cursor: pointer;
      width: 100%;
      height: 50px;
      padding: 0px 16px;
      
      display: flex;
      align-items: center;
      justify-content: flex-start;

      border: none;
      outline: none;
      background-color: transparent;
      
      text-align: left;
      color: #222;
      font-size: 1rem;
      font-weight: bold;
      font-family: 'Nunito', sans-serif;
    }
      .submenu-item.active { 
        cursor: default;
        background-color: #fff;
        color: #C767E5;
        font-size: 20px;
        font-family: 'Nunito', sans-serif;
      }

  .resumen {
    position: relative;
    padding: 10px;
    height: calc(100vh - 168px);

    display: flex;
    justify-content: center;
  }
    .resumen > img {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      height: 60vh;
      object-fit: cover;
    }
  .support {
    position: relative;
    padding: 10px;
    height: calc(100vh - 168px);

    display: flex;
    justify-content: center;
  }
    .support > img {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      height: 60vh;
      object-fit: cover;
    }
}


@media (min-width: 666px) and (max-width: 961px) 
{
  .home {
    position: relative;
    width: 100vw;
    height: 100vh;
    background-color: rgb(250,250,250);
  }

  .home-content {
    width: 100vw;
    height: calc(100vh - 70px);
  }

  .submenu {
    transition-duration: 0.2s;
    position: absolute;
    top: 72px;
    left: -100vw;
    z-index: 222;
    
    width: 100vw;
    display: flex;
    flex-direction: column;
    
    background-color: #46005D;

  }
    .submenu.active { 
      transition-duration: 0.2s;
      left: 0;
    }
    .submenu-item { 
      cursor: pointer;
      width: 100%;
      padding: 0px 8px;
      height: 40px;
      border: none;
      outline: none;
      text-align: left;
      background-color: transparent;
    }
    .submenu-item.active { 
      cursor: default;
      background-color: #fff;
    }
      .submenu-item > span { 
        color: #fff;
        font-size: 20px;
        font-weight: bold;
        font-family: 'Nunito', sans-serif;
      }
        .submenu-item:hover > span { 
          border-bottom: 2px solid #fff;
        }
      .submenu-item.active > span { 
        color: #C767E5;
        font-size: 20px;
        font-family: 'Nunito', sans-serif;
      }

  .resumen {
    position: relative;
    padding: 10px;
    height: calc(100vh - 168px);

    display: flex;
    justify-content: center;
  }
    .resumen > img {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      height: 60vh;
      object-fit: cover;
    }
  .support {
    position: relative;
    padding: 10px;
    height: calc(100vh - 168px);

    display: flex;
    justify-content: center;
  }
    .support > img {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      height: 60vh;
      object-fit: cover;
    }
}

@media (min-width: 961px)  {
  .home {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }
  .home-content {
    width: 100vw;
    height: calc(100vh - 140px);
    display: grid;
    overflow: hidden;
    grid-template-columns: 200px auto; 
  }

  .submenu {
    display: flex;
    flex-direction: column;
    padding: 10px 0px;
    
    background-color: #46005D;

    border-right: 4px solid #D0BDE2;

  }
    .submenu-item { 
      cursor: pointer;
      width: calc(100% + 4px);
      padding: 10px;
      border: none;
      outline: none;
      background-color: transparent;
      
      text-align: left;
      color: #fff;
      font-weight: 500;
      font-size: 1.25rem;

      color: #fff;
      font-size: 1.25rem;
      font-weight: bold;
      font-family: 'Nunito', sans-serif;
    }
      .submenu-item.active { 
        pointer-events: none;
        cursor: default;
        background-color: #f0f0f0;
        color: #222;
        color: #C767E5;
      }
      .submenu-item:hover { 
        color: #C767E5;
        text-decoration: underline;
      }

}