@media (max-width: 666px) {
}    
    

@media (min-width: 666px) and (max-width: 961px) 
{
  .orders-container {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .result-container {
    cursor: default;
    position: absolute;
    z-index: 777;
    height: 100%;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: rgba(50,50,50,0.5);
  }
    .result {
      position: relative;
      height: 300px;
      width: 840px;

      border: 4px solid #D0BDE2;
      border-radius: 10px;

      overflow: hidden;

      -webkit-box-shadow: 0px 0px 2px 3px rgba(50,50,50,0.7);
      -moz-box-shadow: 0px 0px 2px 3px rgba(50,50,50,0.7);
      box-shadow: 0px 0px 2px 3px rgba(50,50,50,0.7); 
    }
      .result-bg {
        margin-top: -10%;
        height: 180%;
        width: auto;

/*        background-image: url('/gifs/fruit-dancing-gif.gif');*/
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
      .result > p {
        position: absolute;
        top: -20px;
        left: 0;
        width: 100%;
        height: 110%;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        background-color: rgba(255,255,255,0.8);
      }
        .result > p > span {
          color: #000;
          font-size: 32px;
          font-family: 'PT Sans', sans-serif;
        }
        .result > p > button {
          cursor: pointer;
          outline: none;
          border: none;
          background-color: transparent;

          color: #000;
          font-size: 14px;
          font-weight: bold;
          font-family: 'Nunito', sans-serif;
          
          border-bottom: 1px solid transparent;
        }
          .result > p > button:hover {
            border-bottom: 1px solid rgb(50,50,50);
          }
          .result > p > button:active {
            color: #adbf5a;
            border-bottom: 1px solid #adbf5a;
          }

    .orders-result-close {
      cursor: default;
      position: absolute;
      right: 10px;
      top: 10px;

      display: flex;
      align-items: center;
      justify-content: center;

      border: none;
      border-radius: 30px;
      outline: none;

      height: 18px;
      width: 18px;

      background-color: transparent;
    }
      .orders-result-close > span {
        cursor: default;
        pointer-events: none;

        color: #000;
        color: red;
        font-size: 12px;
        font-weight: bold; 
      }
      
      .orders-result-close:hover {
        transition-duration: 0.1s;
        background-color: #fff;
        background-color: red;
      }
      
      .orders-result-close:hover > span {
        transition-duration: 0.1s;
        color: red;
        color: #fff;
      }

  .orders {
    position: relative;
    height: calc(100% - 66px);
    margin-top: 10px;
    background-color: transparent;

    text-align: center;
  }
    .orders > h1 {
      height: 50px;
      font-size: 32px;
      font-family: 'PT Sans', sans-serif;
    }

  .orders-columns {
    z-index: 455;
    height: 50px;
    padding: 0px 20px;
    
    display: grid;
    grid-template-columns: 30px 80px auto 20% 40px;
    align-items: center;

    border-bottom: 1px solid #D0BDE2;
  }
    .orders-columns > div {
      height: 100%;

      display: flex;
      align-items: center;
      justify-content: center;

      color: #fff;
      color: #46005D;
      font-size: 24px;
      font-weight: bold;
      font-family: 'Space Mono', sans-serif;
      font-family: 'PT Sans', sans-serif;
    }
    .orders-columns > button {
      height: 40px;
      width: 40px;

      display: flex;
      align-items: center;
      justify-content: center;

      outline: none;
      border: none;
      border: 3px solid #C767E5;
      border-radius: 25px;
      background-color: #C767E5;

      color: #000;
      color: #fff;
      font-size: 24px;
      font-weight: bold;
      font-family: 'PT Sans', sans-serif;

      -webkit-box-shadow: 1px 1px 2px 1px rgba(20,20,20,0.6);
      -moz-box-shadow: 1px 1px 2px 1px rgba(20,20,20,0.6);
      box-shadow: 1px 1px 2px 1px rgba(20,20,20,0.6);
    }

  .orders-list {
    z-index: 555;
    height: calc(100% - 60px);
    
    overflow-y: scroll;
    padding: 6px 8px;

    text-align: center;  
  }
    .more-arrow {
      width: 30px;
      height: 30px;

      display: flex;
      align-items: center;
      justify-content: center;
      
      color: #000;
      color: #8c9674;
      color: #adbf5a;
      font-size: 30px;
    }
      #arrow-up {
        position: absolute;
        z-index: 777;
        top: 50px;
        left: 50%;
        transform: translateX(-50%);
      }
      #arrow-down {
        position: absolute;
        z-index: 777;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
      }


  .orders-new-order-container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 777;
    height: 100%;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: rgba(50,50,50,0.5);
  }

  .orders-new-order {
    position: relative;
    width: 80%;

    padding: 20px 10px;

    border: 4px solid #D0BDE2;
    border-radius: 10px;

    background-color: rgb(250,250,250);

    -webkit-box-shadow: 0px 0px 2px 3px rgba(50,50,50,0.7);
    -moz-box-shadow: 0px 0px 2px 3px rgba(50,50,50,0.7);
    box-shadow: 0px 0px 2px 3px rgba(50,50,50,0.7);  
  }
    .orders-new-order > h2 {
      position: relative;
      margin: 0;
      margin-top: 10px;

      text-align: left;
      color: #fff;
      color: rgb(30,30,30);
      font-size: 24px;
      font-family: 'PT Sans', sans-serif;
    }
    
    .orders-new-order-input {
      text-align: left;
      margin-top: 20px;
    }
      .orders-new-order-input > span {
        margin-left: 2px;

        color: #000;
        font-size: 16px;
        font-family: 'PT Sans', sans-serif; 
      }
      
      .orders-new-order-input > input {
        display: flex;
        margin-top: 6px;
        height: 30px;
        width: 60%;
        
        padding: 0px 10px;
        border: 3px solid #fff;
        border: 3px solid #D0BDE2;
        outline: none;
        border-radius: 4px;
        
        background-color: rgb(240,240,240);

        text-align: left;
        color: #000;
        font-size: 16px;
        font-family: 'Nunito', sans-serif; 

        -webkit-box-shadow: 0px 1px 1px 1px rgba(50,50,50,0.7);
        -moz-box-shadow: 0px 1px 1px 1px rgba(50,50,50,0.7);
        box-shadow: 0px 1px 1px 1px rgba(50,50,50,0.7);
      }
      
        .orders-new-order-input > input:hover,
        .orders-new-order-input > input:focus {
          transition-duration: 0.1s;
          border: 3px solid #C767E5;
          background-color: transparent;

          -webkit-box-shadow: 0px 1px 1px 1px rgba(50,50,50,0.7);
          -moz-box-shadow: 0px 1px 1px 1px rgba(50,50,50,0.7);
          box-shadow: 0px 1px 1px 1px rgba(50,50,50,0.7);
        }

      .orders-new-order-submit-disabled {
        margin-top: 30px;
        height: 40px;

        display: flex;
        align-items: center;
        justify-content: center;

        border: none;
        outline: none;
        border-radius: 25px;

        background-color: #ccc;

        color: #000;
        color: #fff;
        font-size: 18px;
        font-weight: bold;
        font-family: 'Source Code Pro', monospace;
        font-family: 'Nunito', sans-serif;

        -webkit-box-shadow: 0px 2px 2px 2px rgba(50,50,50,0.7);
        -moz-box-shadow: 0px 2px 2px 2px rgba(50,50,50,0.7);
        box-shadow: 0px 2px 2px 2px rgba(50,50,50,0.7);
      }

      .orders-new-order-submit, .orders-new-order-submit:focus {
        cursor: pointer;

        position: absolute;
        bottom: 20px;
        right: 30px;

        height: 40px;
        width: 150px;

        display: flex;
        align-items: center;
        justify-content: center;

        border: none;
        outline: none;
        border-radius: 25px;

        background-color: #C767E5;

        color: #000;
        color: #fff;
        font-size: 18px;
        font-weight: bold;
        font-family: 'Source Code Pro', monospace;
        font-family: 'Nunito', sans-serif;

        -webkit-box-shadow: 0px 2px 2px 2px rgba(50,50,50,0.7);
        -moz-box-shadow: 0px 2px 2px 2px rgba(50,50,50,0.7);
        box-shadow: 0px 2px 2px 2px rgba(50,50,50,0.7);
      }
        
        .orders-new-order-submit:hover {
          transition-duration: 0.1s;
          border: 3px solid #C767E5;
          color: #C767E5;
          background-color: rgb(250,250,250);
        }

        .orders-new-order-submit:active {
          transition-duration: 0.1s;

          margin-top: 23px;
          border: 3px solid #adbf5a;
          color: #adbf5a;
          background-color: rgb(250,250,250);

          -webkit-box-shadow: 0px 0px 0px 0px rgba(50,50,50,0.7);
          -moz-box-shadow: 0px 0px 0px 0px rgba(50,50,50,0.7);
          box-shadow: 0px 0px 0px 0px rgba(50,50,50,0.7);
        }

    
    .orders-new-order-close {
      cursor: default;
      position: absolute;
      right: 10px;
      top: 10px;

      display: flex;
      align-items: center;
      justify-content: center;

      border: none;
      border-radius: 30px;
      outline: none;

      height: 18px;
      width: 18px;

      background-color: transparent;
    }
      .orders-new-order-close > span {
        cursor: default;
        pointer-events: none;

        color: #000;
        color: red;
        font-size: 12px;
        font-weight: bold; 
      }
      
      .orders-new-order-close:hover {
        transition-duration: 0.1s;
        background-color: #fff;
        background-color: red;
      }
      
      .orders-new-order-close:hover > span {
        transition-duration: 0.1s;
        color: red;
        color: #fff;
      }

  .orders-delete-order-container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 777;
    height: 100%;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: rgba(50,50,50,0.5);
  }

  .orders-delete-order {
    position: relative;
    width: 90%;

    border: 2px solid #adbf5a;
    border-radius: 10px;

    background-color: rgb(250,250,250);

    -webkit-box-shadow: 0px 0px 2px 3px rgba(50,50,50,0.7);
    -moz-box-shadow: 0px 0px 2px 3px rgba(50,50,50,0.7);
    box-shadow: 0px 0px 2px 3px rgba(50,50,50,0.7);  
  }
    .orders-delete-order > h2 {
      width: 100%;
      margin: 20px 0px;

      text-align: center;
      color: #fff;
      color: rgb(30,30,30);
      font-size: 30px;
      font-family: 'PT Sans', sans-serif;
    }
    .orders-delete-order > p {
      margin: 0px 40px;

      color: rgb(30,30,30);
      font-size: 18px;
      font-family: 'Nunito', sans-serif;
    }
      .orders-delete-order > p > span {
        font-weight: bold;   
      }

    .orders-delete-order-actions {
      margin: 20px 10px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;
    } 
    .orders-delete-order-cancel, 
    .orders-delete-order-cancel:focus {
        height: 40px;

        display: flex;
        align-items: center;
        justify-content: center;

        border: none;
        outline: none;
        border-radius: 25px;

        background-color: transparent;

        color: #000;
        font-size: 18px;
        font-weight: bold;
        font-family: 'PT Sans', sans-serif;
      }

    .orders-delete-order-submit, 
    .orders-delete-order-submit:focus {
        height: 40px;
        width: 150px;

        display: flex;
        align-items: center;
        justify-content: center;

        border: none;
        outline: none;
        border-radius: 25px;

        background-color: #adbf5a;

        color: #000;
        color: #fff;
        font-size: 18px;
        font-weight: bold;
        font-family: 'PT Sans', sans-serif;

        -webkit-box-shadow: 0px 2px 2px 2px rgba(50,50,50,0.7);
        -moz-box-shadow: 0px 2px 2px 2px rgba(50,50,50,0.7);
        box-shadow: 0px 2px 2px 2px rgba(50,50,50,0.7);
      }
}

@media (min-width: 961px)  {

  .result-container {
    cursor: default;
    position: absolute;
    z-index: 777;
    height: 100%;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: rgba(50,50,50,0.5);
  }
    .result {
      position: relative;
      height: 300px;
      width: 840px;

      border: 4px solid #D0BDE2;
      border-radius: 10px;

      overflow: hidden;

      -webkit-box-shadow: 0px 0px 2px 3px rgba(50,50,50,0.7);
      -moz-box-shadow: 0px 0px 2px 3px rgba(50,50,50,0.7);
      box-shadow: 0px 0px 2px 3px rgba(50,50,50,0.7); 
    }
      .result-bg {
        margin-top: -10%;
        height: 180%;
        width: auto;

/*        background-image: url('/gifs/fruit-dancing-gif.gif');*/
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
      
      .result > p {
        position: absolute;
        top: -20px;
        left: 0;
        width: 100%;
        height: 110%;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        background-color: rgba(255,255,255,0.8);
      }
        .result > p > span {
          color: #000;
          font-size: 32px;
          font-family: 'PT Sans', sans-serif;
        }
        .result > p > button {
          cursor: pointer;
          outline: none;
          border: none;
          background-color: transparent;

          color: #000;
          font-size: 14px;
          font-weight: bold;
          font-family: 'Nunito', sans-serif;
          
          border-bottom: 1px solid transparent;
        }  
          .result > p > button:hover {
            border-bottom: 1px solid rgb(50,50,50);
          }
          .result > p > button:active {
            color: #adbf5a;
            border-bottom: 1px solid #adbf5a;
          }

    .result-close {
      cursor: default;
      position: absolute;
      right: 10px;
      top: 10px;

      display: flex;
      align-items: center;
      justify-content: center;

      border: none;
      border-radius: 30px;
      outline: none;

      height: 18px;
      width: 18px;

      background-color: transparent;
    }
      .result-close > span {
        cursor: default;
        pointer-events: none;

        color: #000;
        color: red;
        font-size: 12px;
        font-weight: bold; 
      }
      .result-close:hover {
        transition-duration: 0.1s;
        background-color: #fff;
        background-color: red;
      }
      .result-close:hover > span {
        transition-duration: 0.1s;
        color: red;
        color: #fff;
      }

}