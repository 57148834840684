@media (max-width: 666px) {
  .item {
    position: relative;
    display: grid;
    grid-template-columns: auto 40px;
    
    margin-bottom: 4px;

    border: 1px solid #D0BDE2;
    border-radius: 8px;
  }
    .item-data {
      position: relative;
      padding: 8px;
      padding-left: 0px;
      display: grid;
      grid-template-columns: 30px 60px 80px auto 200px 100px;
      grid-gap: 8px;
      align-items: center;
    }
      .item-data.active {
        grid-template-columns: 30px auto 100px;
      }
      .item-data > input {
        height: 40px;
        text-align: center;
      }
      .item-index {
        display: flex;
        align-items: center;
        justify-content: center;

        margin: 10px 0px;
        border-right: 1px solid #ccc;

        font-size: 0.75rem;
        font-weight: bold;
        font-family: 'Nunito', sans-serif;        
      }

      .item-subdata {
        padding-left: 6px;
        border-left: 1px solid #ccc;
        text-align: left;
      }

  .order-notes {
    padding: 8px;
  }

    
  .item-changes {
    width: 100%;

    display: grid;
    grid-template-columns: 1fr;
    
    margin: 4px 0px;
    border-left: 1px solid #ccc;
  }
    .item-changes > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
      .item-changes > div > button {
        display: flex;
        align-items: center;
        justify-content: center;

        height: 24px;
        width: 24px;
        
        border: none;
        outline: none;
        border-radius: 30px;

        background-color: red;
      }
        .item-changes > div > button:hover {
          background-color: rgba(250,0,0,0.6);
        }
          .item-changes > div > button:hover > span {
            font-weight: bold
          }
    
      #item-save-changes {
        background-color: green;
      }
        #item-save-changes:hover {
          background-color: rgba(0,250,0,0.6);
        }
          #item-save-changes:hover > span {
            font-weight: bold
          }
      

      .item-changes > div > button > span {
        pointer-events: none;
        color: #fff;
        font-size: 0.75rem; 
      }

  .item-delete {
    display: flex;
    align-items: center;
    justify-content: center;

    margin: 10px 0px;
    border-left: 1px solid #ccc;
  }
    .item-delete > button {
      display: flex;
      align-items: center;
      justify-content: center;

      height: 20px;
      width: 20px;
      
      border: none;
      outline: none;
      border-radius: 30px;

      background-color: #777;
    }
      .item-delete > button:hover {
        background-color: #aaa;
      }
      .item-delete > button > span {
        pointer-events: none;
        color: #fff;
        font-size: 0.75rem; 
      }

  .item-action {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    
    border: none;
    outline: none;
    background-color: transparent;
  }
}

@media (min-width: 666px) and (max-width: 961px) 
{
  .item {
    z-index: 666;
    display: grid;
    grid-template-columns: 20px auto 30px;

    margin-bottom: 4px;

    border: 1px solid #D0BDE2;
    border-radius: 4px;
  }
    .item > span {
      display: flex;
      align-items: center;
      justify-content: center;

      margin: 10px 0px;
      border-right: 1px solid #ccc;

      color: #000;
      color: #46005D;
      font-size: 12px;
      font-weight: bold;
      font-family: 'Nunito', sans-serif;        
    }

    .item-data {
      position: relative;
      margin: 4px 2px;

      display: grid;
      grid-template-columns: 26px 26px auto 60px;
      grid-gap: 4px;
      align-items: center;
    }
      .item-data.active {
        grid-template-columns: auto 60px;
      }

      .item-data > input[type=numeric] {
        min-height: 30px;

        border: 2px solid #D0BDE2;
        border: none;

        border-radius: 4px;
        outline: none;
        background-color: rgb(245,245,245);
        background-color: transparent;
        
        text-align: center;
        color: #fff;
        color: #000;
        font-size: 12px;
        font-family: 'PT Sans', sans-serif;
        font-family: 'Nunito', sans-serif;
      }

      .item-subdata {
        padding-left: 6px;
        border-left: 1px solid #ccc;
        text-align: left;

        color: #fff;
        color: rgb(60,60,60);
        font-size: 14px;
        font-family: 'PT Sans', sans-serif;
        font-family: 'Nunito', sans-serif;
      }

    
  .item-changes {
    width: 100%;

    display: grid;
    grid-template-columns: 1fr;
    
    margin: 4px 0px;
    border-left: 1px solid #ccc;
  }

    .item-changes > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .item-changes > div > button {
      display: flex;
      align-items: center;
      justify-content: center;

      height: 17px;
      width: 17px;
      
      border: none;
      outline: none;
      border-radius: 30px;

      background-color: red;
    }
      .item-changes > div > button:hover {
        background-color: rgba(250,0,0,0.6);
      }
        .item-changes > div > button:hover > span {
          font-weight: bold
        }
    
      #item-save-changes {
        background-color: green;
      }
        #item-save-changes:hover {
          background-color: rgba(0,250,0,0.6);
        }
          #item-save-changes:hover > span {
            font-weight: bold
          }
      

      .item-changes > div > button > span {
        pointer-events: none;
        color: #fff;
        font-size: 9px; 
      }

  .item-delete {
    z-index: 677;
    display: flex;
    align-items: center;
    justify-content: center;

    margin: 10px 0px;
    border-left: 1px solid #ccc;
  }
    .item-delete > button {
      display: flex;
      align-items: center;
      justify-content: center;

      height: 17px;
      width: 17px;
      
      border: none;
      outline: none;
      border-radius: 30px;

      background-color: gray;
    }
      .item-delete > button > span {
        pointer-events: none;
        color: #fff;
        font-size: 11px; 
      }
}

@media (min-width: 961px)  {
  .item {
    position: relative;
    display: grid;
    grid-template-columns: auto 40px;
    
    margin-bottom: 4px;

    border: 1px solid #D0BDE2;
    border-radius: 8px;
  }
    .item-data {
      position: relative;
      padding: 8px;
      padding-left: 0px;
      display: grid;
      grid-template-columns: 30px 60px 80px auto 200px 100px;
      grid-gap: 8px;
      align-items: center;
    }
      .item-data.active {
        grid-template-columns: 30px auto 100px;
      }
      .item-data > input {
        height: 40px;
        text-align: center;
      }
      .item-index {
        display: flex;
        align-items: center;
        justify-content: center;

        margin: 10px 0px;
        border-right: 1px solid #ccc;

        font-size: 0.75rem;
        font-weight: bold;
        font-family: 'Nunito', sans-serif;        
      }

      .item-subdata {
        padding-left: 6px;
        border-left: 1px solid #ccc;
        text-align: left;
      }

  .order-notes {
    padding: 8px;
  }

    
  .item-changes {
    width: 100%;

    display: grid;
    grid-template-columns: 1fr;
    
    margin: 4px 0px;
    border-left: 1px solid #ccc;
  }
    .item-changes > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
      .item-changes > div > button {
        display: flex;
        align-items: center;
        justify-content: center;

        height: 24px;
        width: 24px;
        
        border: none;
        outline: none;
        border-radius: 30px;

        background-color: red;
      }
        .item-changes > div > button:hover {
          background-color: rgba(250,0,0,0.6);
        }
          .item-changes > div > button:hover > span {
            font-weight: bold
          }
    
      #item-save-changes {
        background-color: green;
      }
        #item-save-changes:hover {
          background-color: rgba(0,250,0,0.6);
        }
          #item-save-changes:hover > span {
            font-weight: bold
          }
      

      .item-changes > div > button > span {
        pointer-events: none;
        color: #fff;
        font-size: 0.75rem; 
      }

  .item-delete {
    display: flex;
    align-items: center;
    justify-content: center;

    margin: 10px 0px;
    border-left: 1px solid #ccc;
  }
    .item-delete > button {
      display: flex;
      align-items: center;
      justify-content: center;

      height: 20px;
      width: 20px;
      
      border: none;
      outline: none;
      border-radius: 30px;

      background-color: #777;
    }
      .item-delete > button:hover {
        background-color: #aaa;
      }
      .item-delete > button > span {
        pointer-events: none;
        color: #fff;
        font-size: 0.75rem; 
      }

  .item-action {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    
    border: none;
    outline: none;
    background-color: transparent;
  }
}