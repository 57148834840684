@media (max-width: 666px) {
	.edit-item-container {
		position: relative;
		width: calc(100% - 32px);
		padding: 16px;

		border: 2px solid #D0BDE2;
		border: 2px solid #C767E5;
		border-radius: 16px;
		box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
	}
		.edit-item-container h2 {
			margin-bottom: 20px;

			text-align: left;
		  font-family: 'Nunito', sans-serif;        
		  font-weight: bold;
		}

	.edit-item-data {
		width: 100%;
	}

	.edit-item-input {
		width: calc(100% - 10px);
		margin-bottom: 10px;
	}
		.edit-item-input p {
			margin: 0;
			margin-bottom: 4px;
			width: 100%;

			text-align: left;
			color: #000;
			color: #C767E5;
			color: #245021;
		  font-size: 16px;
		  font-weight: bold;
		  font-family: 'PT Sans', sans-serif;
		}
}

@media (min-width: 666px) and (max-width: 961px) 
{
	.edit-item-container {
		position: absolute;
		z-index: 888;
		top: 8px;
		left: 8px;
		width: calc(100% - 36px);
		height: calc(100% - 36px);
		padding: 8px;

		border: 2px solid #D0BDE2;
		border-radius: 10px;
		
		background-color: #D0BDE2;
		background-color: #fff;
	}
		.edit-item-container h1 {
			margin: 20px 0px;

			text-align: center;
			color: #000;
			color: #C767E5;
		  font-size: 26px;
		  font-family: 'Nunito', sans-serif;        
		}

	.edit-item-data {
		margin: 8px;
		width: 100%;
	}

	.edit-item-input {
		width: calc(100% - 10px);
		margin-bottom: 10px;
	}
		.edit-item-input p {
			margin: 0;
			margin-bottom: 4px;
			width: 100%;

			text-align: left;
			color: #000;
			color: #C767E5;
			color: #245021;
		  font-size: 16px;
		  font-weight: bold;
		  font-family: 'PT Sans', sans-serif;
		}
		.edit-item-input > input {
      height: 30px;
      width: calc(100% - 14px);
      display: flex;
      padding-left: 10px;

      border: 1px solid #D0BDE2;
      border: none;
      border-radius: 4px;
      
      background-color: rgb(240,240,240);

      text-align: left;
      color: #000;
      color: #222;
      font-size: 14px;
      font-weight: 500;
      font-family: 'Nunito', sans-serif; 

      -webkit-box-shadow: 0px 1px 1px 1px rgba(50,50,50,0.7);
      -moz-box-shadow: 0px 1px 1px 1px rgba(50,50,50,0.7);
      box-shadow: 0px 1px 1px 1px rgba(50,50,50,0.7);
    }
      .orders-add-product-input > input:hover,
      .orders-add-product-input > input:focus {
        outline: 1px solid #C767E5;
        background-color: #fff;
      }
}

@media (min-width: 961px)  {
	.edit-item-container {
		position: relative;
		width: 400px;
		padding: 16px;

		border: 2px solid #D0BDE2;
		border: 2px solid #C767E5;
		border-radius: 16px;
		box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
	}
		.edit-item-container h2 {
			margin-bottom: 20px;

			text-align: left;
		  font-family: 'Nunito', sans-serif;        
		  font-weight: bold;
		}

	.edit-item-data {
		width: 100%;
		display: flex;
		flex-direction: column;
		grid-gap: 8px;
	}

	.edit-item-input {
		width: calc(100% - 10px);
		margin-bottom: 10px;
	}
		.edit-item-input p {
			margin: 0;
			margin-bottom: 4px;
			width: 100%;

			text-align: left;
			color: #000;
			color: #C767E5;
			color: #245021;
		  font-size: 16px;
		  font-weight: bold;
		  font-family: 'PT Sans', sans-serif;
		}
}