
@media (max-width: 666px)  {
  .validate {
    position: relative;
    width: 100vw;
    height: 100%;

    overflow: hidden;

    background-color: #fff;
  }
    .validate > h1 {
      width: 100%;
      text-align: center;
      color: #000;
      font-size: 32px;
      font-family: 'PT Sans', sans-serif;
    }
}

@media (min-width: 666px) and (max-width: 961px) 
{
  .validate {
    position: relative;
    width: 100vw;
    height: 100%;

    overflow: hidden;

    background-color: #fff;
  }
    .validate > h1 {
      width: 100%;
      text-align: center;
      color: #000;
      font-size: 32px;
      font-family: 'PT Sans', sans-serif;
    }
    .loading {
      width: auto;
      height: 60vh;

/*      background-image: url('/gifs/fruits_gif2.gif');*/
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
}

@media (min-width: 961px)  {
  .validate {
    position: relative;
    width: 100%;
    height: 100%;

    overflow: hidden;
  }
    .validate > h1 {
      width: 100%;
      text-align: center;
      color: #000;
      font-size: 32px;
      font-family: 'PT Sans', sans-serif;
    }

    .loading {
      width: auto;
      height: 60vh;
    }
}