/*
  #222222 || rgb(34,34,34)
  #46005D || rgb(70,0,93)
  #C767E5 || rgb(199,103,229)
  #D0BDE2 || rgb(208,189,226)
  #F0F0F0 || rgb(240,240,240)
  #245021 || rgb(36,80,33)
*/

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  background-color: #F0F0F0;
  font-family: 'PT Sans', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


button {
  transition-duration: 0.3s;
  color: #374258;
  font-size: 0.875rem;
  font-weight: 700;
  font-family: 'Nunito', sans-serif;
}
  button:active {
    transition-duration: 0s;
  }
  button:disabled {
    pointer-events: none;
  }
a {
  color: inherit;
  font-family: inherit;
  text-decoration: none;
}
i {
  font-size: inherit;
  pointer-events: none;
}
h1 {
  font-size: 2rem;
}
h2 {
  font-size: 1.5rem;
  font-weight: 600;
}
h3 {
  font-size: 1rem;
  font-size: 1.125rem;
}
h4 {
  font-size: 0.875rem;
}
h5 {
  font-size: 0.75rem;
}
p {
  font-size: 0.875rem;
}
input {
  font-size: 0.875rem;
  font-family: 'PT Sans', sans-serif;
}
textarea {
  font-size: 0.875rem;
  font-family: 'PT Sans', sans-serif;
}
label {
  font-size: 0.875rem;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.bg {
  background-color: #F0F0F0;
}
.bg2 {
  background-color: #fff;
}
.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button {
  cursor: pointer;
  border: none;
  outline: none;
  border-radius: 8px;
}
  
  .button.primary {
    background-color: #C767E5;
    color: #fff;
  }
    .button.primary:hover {
      background-color: #46005D;
    }
    .button.primary:active {
      background-color: #46005Dcc;
    }
    .button.primary.active {
      background-color: #C767E5;
    } 
      .button.primary.active:hover {
        border: 3px solid #C767E5;
        color: #C767E5;
        background-color: rgb(250,250,250);
      }
      .button.primary.active:active {
        border: 3px solid #adbf5a;
        color: #adbf5a;
        background-color: rgb(250,250,250);
      }
  
  .button.secondary {
    outline: none;
    border: 1px solid #D0BDE2;
    background-color: transparent;

    color: #C767E5;
    color: #D0BDE2;
    color: #222222;
  }
    .button.secondary:hover {
      border: 1px solid #C767E5;
      color: #C767E5;
    }
    .button.secondary:active {
      background-color: #D0BDE2;
    }
    .button.secondary.active {
      outline: 2px solid #C767E5;
      border: 2px solid #C767E5;
      color: #C767E5;
    }
  .button:disabled {
    background-color: #ccc;
  }

.input {
  transition-duration: 0.3s;
  height: 40px;
  padding: 0px 8px;
  
  border: 1px solid #D0BDE2;
  outline: 1px solid transparent;
  border-radius: 8px;
  background-color: transparent;

  color: #222;
  font-family: 'PT Sans', sans-serif; 
}
  .input:hover,
  .input:focus {
    border: 1px solid #C767E5;
    outline: 2px solid #C767E5;
    background-color: #fff;
  }

  .input.textarea {
    padding: 8px;
    min-height: 12rem;
  }

