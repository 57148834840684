@media (max-width: 666px) {
	.select-s {
		position: relative;
		width: 100%;
	}

	.selected-s, 
	.selected-s:active, 
	.selected-s:focus {
		cursor: pointer;
		height: 40px;
		padding: 0px 16px;

		outline: none;
		border-radius: 8px;
		background-color: transparent;

		color: #000;
	  font-size: 1.125rem;
	  font-weight: 800;
	  font-family: 'Nunito', sans-serif;        
	}

		#selected-s-open,
		#selected-s-converted {
			color: #444;
		}
		#selected-s-validated,
		#selected-s-consolidated {
			color: #fff;
		}
		
		#selected-s-open:hover,
		#selected-s-converted:hover,
		#selected-s-validated:hover,
		#selected-s-consolidated:hover {
			border: 2px solid #C767E5;

			color: #222;
			background-color: #fff;

		  box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.3);
		}

	.select-s-items-none {
		display: none;
	}

	.select-s-items {
		z-index: 999;
		padding: 0;
		overflow: hidden;

		position: absolute;
		right: 0px;

		border: 2px solid #D0BDE2;
		border: 2px solid #C767E5;
		border-radius: 8px;
	  box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.2);
	}

	.select-s-item {
		display: flex;
		align-items: center;
		justify-content: flex-start;

		height: 40px;
		width: 100%;
		padding: 0px 16px;

		border: none;
		outline: none;
		background-color: transparent;

		color: #222;
	  font-size: 0.875rem;
	  font-family: 'Nunito', sans-serif;        
	  
	  overflow: hidden;
	  text-overflow: ellipsis;
	  white-space: nowrap;
	}

		.select-s-item-hover {
			background-color: #D0BDE2;
			color: #000;
		}

		.select-s-item.active {
			outline: none;
			color: #fff;
			background-color: #C767E5;
		}
			.select-s-item.active:hover {
				color: #fff;
				background-color: #C767E5;
			}
}


@media (min-width: 666px) and (max-width: 961px) 
{
	.select-s {
		position: relative;
		width: 100%;
	}

	.selected-s, 
	.selected-s:active, 
	.selected-s:focus {
		cursor: pointer;

		outline: none;
		border: none;
		border-radius: 4px;

		background-color: transparent;

		color: #000;
	  font-size: 14px;
	  font-weight: 800;
	  font-family: 'Nunito', sans-serif;        
	}

		#selected-s-open,
		#selected-s-converted {
			color: #444;
		}
		#selected-s-validated,
		#selected-s-consolidated {
			color: #fff;
		}
		
		#selected-s-open:hover,
		#selected-s-converted:hover,
		#selected-s-validated:hover,
		#selected-s-consolidated:hover {
			border: 2px solid #C767E5;

			color: #000;
			background-color: #fff;

			font-weight: normal;

			-webkit-box-shadow: 0px 1px 1px 1px rgba(50,50,50,0.7);
		  -moz-box-shadow: 0px 1px 1px 1px rgba(50,50,50,0.7);
		  box-shadow: 0px 1px 1px 1px rgba(50,50,50,0.7);
		}

	.select-s-items-none {
		display: none;
	}

	.select-s-items {
		z-index: 999;
		padding: 0;

		position: absolute;
		right: 0px;
		width: 160px;
		max-height: 140px;

		border: 1px solid #D0BDE2;
		border-radius: 10px;

		background-color: rgb(250,250,250);

		-webkit-box-shadow: 0px 1px 2px 1px rgba(50,50,50,0.7);
	  -moz-box-shadow: 0px 1px 2px 1px rgba(50,50,50,0.7);
	  box-shadow: 0px 1px 2px 1px rgba(50,50,50,0.7);
	}

	.select-s-item, 
	.select-s-item:focus, 
	.select-s-item:active {
		display: flex;
		align-items: center;
		justify-content: center;

		height: 32px;
		width: 100%;
		padding: 0px 10px;

		border: none;
		outline: none;

		background-color: transparent;

		color: #000;
	  font-size: 12px;
	  font-family: 'Nunito', sans-serif;        
	  
	  overflow: hidden;
	  text-overflow: ellipsis;
	  white-space: nowrap;
	}

		.select-s-item-hover, 
		.select-s-item-hover:focus, 
		.select-s-item-hover:active {
			display: flex;
			align-items: center;
			justify-content: center;

			height: 32px;
			width: 100%;
			padding: 0px 10px;

			list-style-type: none;
			border: none;
			outline: none;

			background-color: transparent;
			background-color: #D0BDE2;

			color: #000;
		  font-size: 12px;
		  font-family: 'Nunito', sans-serif;        
		  
		  overflow: hidden;
		  text-overflow: ellipsis;
		  white-space: nowrap;

		}

		.select-s-item.active {
			outline: none;
			color: #fff;
			background-color: #C767E5;
		}
			.select-s-item.active:hover {
				color: #fff;
				background-color: #C767E5;
			}
}

@media (min-width: 961px)  {
	.select-s {
		position: relative;
		width: 100%;
	}

	.selected-s, 
	.selected-s:active, 
	.selected-s:focus {
		cursor: pointer;
		height: 40px;
		padding: 0px 16px;

		outline: none;
		border-radius: 8px;
		background-color: transparent;

		color: #000;
	  font-size: 0.875rem;
	  font-weight: 800;
	  font-family: 'Nunito', sans-serif;        
	}

		#selected-s-open,
		#selected-s-converted {
			color: #444;
		}
		#selected-s-consolidated {
			color: #fff;
		}
		
		#selected-s-open:hover,
		#selected-s-converted:hover,
		#selected-s-validated:hover,
		#selected-s-consolidated:hover {
			border: 2px solid #C767E5;

			color: #222;
			background-color: #fff;

		  box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.3);
		}

	.select-s-items-none {
		display: none;
	}

	.select-s-items {
		z-index: 999;
		padding: 0;
		width: 60%;
		overflow: hidden;

		position: absolute;
		left: 20%;

		border: 2px solid #D0BDE2;
		border: 2px solid #C767E5;
		border-radius: 8px;
	  box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.2);
	}

	.select-s-item {
		display: flex;
		align-items: center;
		justify-content: flex-start;

		height: 40px;
		width: 100%;
		padding: 0px 16px;

		border: none;
		outline: none;
		background-color: transparent;

		color: #222;
	  font-size: 0.875rem;
	  font-family: 'Nunito', sans-serif;        
	  
	  overflow: hidden;
	  text-overflow: ellipsis;
	  white-space: nowrap;
	}

		.select-s-item-hover {
			background-color: #D0BDE2;
			color: #000;
		}

		.select-s-item.active {
			outline: none;
			color: #fff;
			background-color: #C767E5;
		}
			.select-s-item.active:hover {
				color: #fff;
				background-color: #C767E5;
			}
}