@media (max-width: 666px) {
	.select-add {
		position: relative;
		width: 100%;
	}

	.select-add > button {
		height: 40px;
		padding: 0px 16px;
	}

	.select-add-items-none {
		display: none;
	}

	.select-add-items {
		position: absolute;
		z-index: 999;
		top: 100%;
		left: 0;
		max-height: 140px;
		padding: 0;
		margin-top: 2px;
		
		border-radius: 8px;
		background-color: rgb(250,250,250);
		overflow-y: auto;

	  box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.2);
	}

	.select-add-item {
		cursor: default;

		height: 32px;
		width: 100%;
		padding: 0px 16px;

		display: flex;
		align-items: center;
		justify-content: flex-start;

		list-style-type: none;
		border: none;
		outline: none;
		background-color: transparent;

		color: #222;
	  font-size: 0.875rem;
	  font-weight: 700;
	  font-family: 'Nunito', sans-serif;        
	  
	  overflow: hidden;
	  text-overflow: ellipsis;
	  white-space: nowrap;
	}
		.select-add-item.active {
			outline: none;
			color: #fff;
			background-color: #C767E5 !important;
		}
			.select-add-item.active:hover {
				background-color: #C767E5;
			}
}


@media (min-width: 666px) and (max-width: 961px) 
{
	.select-add {
		position: relative;
		width: 100%;
	}

	.selected-add {
		width: calc(100% - 12px);
		height: 30px;
		padding-left: 10px;
		
		display: flex;
		align-items: center;
		justify-content: flex-start;

		outline: none;
		border: none;
		outline: 1px solid #D0BDE2;
		border-radius: 4px;

		background-color: rgba(240,240,240);

		color: #000;
	  font-size: 16px;
	  font-family: 'PT Sans', sans-serif;        
	  font-family: 'Nunito', sans-serif;        

	  -webkit-box-shadow: 0px 1px 1px 1px rgba(50,50,50,0.7);
	  -moz-box-shadow: 0px 1px 1px 1px rgba(50,50,50,0.7);
	  box-shadow: 0px 1px 1px 1px rgba(50,50,50,0.7);
	}
		.selected-add.active {
			transition-duration: 0.1s;
			outline: 1px solid #C767E5;
		}

	.select-add-items-none {
		display: none;
	}

	.select-add-items {
		position: absolute;
		z-index: 999;
		top: 34px;
		left: 50%;
		transform: translateX(-50%);

		padding: 0;
		margin-top: 2px;
		
		width: 100%;
		max-height: 140px;

		border: 1px solid #D0BDE2;
		border-radius: 10px;

		background-color: rgb(250,250,250);
		overflow-y: scroll;

		-webkit-box-shadow: 0px 1px 2px 1px rgba(50,50,50,0.7);
	  -moz-box-shadow: 0px 1px 2px 1px rgba(50,50,50,0.7);
	  box-shadow: 0px 1px 2px 1px rgba(50,50,50,0.7);
	}

	.select-add-items::-webkit-scrollbar {
	  -webkit-appearance: none;
	  width: 7px;
	}

	.select-add-items::-webkit-scrollbar-thumb {
	  border-radius: 4px;
	  background-color: rgba(0, 0, 0, .5);
	  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
	}

	.select-add-item {
		cursor: default;

		display: flex;
		align-items: center;
		justify-content: center;

		height: 32px;
		width: 100%;
		padding: 0px 10px;

		list-style-type: none;
		border: none;
		outline: none;

		background-color: transparent;

		color: #000;
	  font-size: 14px;
	  font-family: 'Nunito', sans-serif;        
	  
	  overflow: hidden;
	  text-overflow: ellipsis;
	  white-space: nowrap;
	}

		.select-add-item-hover {
			cursor: default;

			display: flex;
			align-items: center;
			justify-content: center;

			height: 32px;
			width: 100%;
			padding: 0px 10px;

			list-style-type: none;
			border: none;
			outline: none;

			background-color: #D0BDE2;

			color: #000;
		  font-size: 12px;
		  font-family: 'Nunito', sans-serif;        
		  
		  overflow: hidden;
		  text-overflow: ellipsis;
		  white-space: nowrap;

		}

		.select-add-item.active {
			outline: none;
			color: #fff;
			background-color: #C767E5;
		}
			.select-add-item.active:hover {
				background-color: #C767E5;
			}
}

@media (min-width: 961px)  {
	.select-add {
		position: relative;
	}

		.select-add > button {
			height: 40px;
			padding: 0px 20px;
		}

	.select-add-items {
		position: absolute;
		left: 0;
		top: 100%;
		margin-top: 4px;
		max-height: 140px;
		z-index: 999;

		border: 1px solid #D0BDE2;
		border-radius: 10px;
		background-color: rgb(250,250,250);
		overflow-y: scroll;

	 box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.1);
	}
		.select-add-item {
			cursor: default;

			display: flex;
			align-items: center;
			justify-content: flex-start;

			height: 36px;
			width: 100%;
			padding: 0px 10px;

			list-style-type: none;
			border: none;
			outline: none;
			background-color: transparent;

			color: #000;
		  font-size: 14px;
		  font-family: 'Nunito', sans-serif;        
		  
		  overflow: hidden;
		  text-overflow: ellipsis;
		  white-space: nowrap;
		}
			.select-add-item.active {
				outline: none;
				color: #fff;
				background-color: #C767E5;
			}
				.select-add-item.active:hover {
					color: #fff;
					background-color: #C767E5;
				}
}