@media (max-width: 666px)  {
  .order-container {
    position: absolute;
    z-index: 555;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100vh - 70px);

    margin: 0;

    background-color: #fff;
  }

  .order-summary {
    position: relative;
    width: 100%;
    height: 80px;
    padding: 8px 16px;

    border-bottom: 2px solid #D0BDE2;
  } 
    .order-summary-data {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;
    }
      .order-summary-data h5 {
      }

    .order-summary-status {
      height: 40px;
      width: 100%;
      margin-top: 6px;
      
      display: flex;
      align-items: center;
      justify-content: flex-start;
      grid-gap: 8px;
    } 
      .order-summary-status button {
        height: 40px;
        padding: 0px 16px;
      }
      

    .order-summary-action {
      position: absolute;
      bottom: 8px;
      right: 0;
      padding-right: 16px;

      display: flex;
      grid-gap: 4px;
    }
      .order-summary-action button {
        transition-duration: 0.3s;
        height: 40px;
        width: 40px;
      }
      .order-summary-action a {
        transition-duration: 0.3s;
        height: 40px;
        width: 40px;
      }

    .order-summary-close {
      cursor: pointer;
      height: 50px;
      width: 50px;

      display: flex;
      align-items: center;
      justify-content: center;

      border-radius: 100px;
      border: 2px solid #245021;
      outline: none;
      background-color: #245021;

      -webkit-box-shadow: 1px 1px 2px 1px rgba(50,50,50,0.7);
      -moz-box-shadow: 1px 1px 2px 1px rgba(50,50,50,0.7);
      box-shadow: 1px 1px 2px 1px rgba(50,50,50,0.7);  
    }
      .order-summary-close > span {
        color: #46005D;
        color: #fff;
        font-size: 12px;
        font-weight: bold; 
        font-family: 'PT Sans', sans-serif;
      }


  .order-list-items {
    position: relative;
    height: calc(100% - 80px);
    width: 100%;
    overflow: auto;
  }
    .order-list-items > div {
      position: absolute;
      top: 0;
      left: 0;
      padding: 16px;
      padding-top: 8px;
    }
    .product-delete {
      display: flex;
      align-items: center;
      justify-content: center;

      margin: 10px 0px;
      border-left: 1px solid #ccc;
    }
      .product-delete > button {
        display: flex;
        align-items: center;
        justify-content: center;

        height: 17px;
        width: 17px;
        
        border: none;
        outline: none;
        border-radius: 30px;

        background-color: gray;
      }
        .product-delete > button > span {
          pointer-events: none;
          color: #fff;
          font-size: 9px; 
        }

  .orders-add-product-container {
    cursor: default;
    position: absolute;
    z-index: 777;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: rgba(50,50,50,0.5);
  }

  .orders-add-product {
    position: relative;
    height: 86%;
    width: 86%;

    padding: 10px;

    border: 2px solid #D0BDE2;
    border-radius: 10px;

    background-color: rgb(250,250,250);

    -webkit-box-shadow: 0px 0px 2px 1px rgba(50,50,50,0.7);
    -moz-box-shadow: 0px 0px 2px 1px rgba(50,50,50,0.7);
    box-shadow: 0px 0px 2px 1px rgba(50,50,50,0.7);  
  }
    .orders-add-product > h2 {
      margin: 0;
      margin-bottom: 10px;
      color: #fff;
      color: #46005D;
      color: #C767E5;
      font-size: 24px;
      font-family: 'PT Sans', sans-serif;
    }
    
    .orders-add-product-form {
      position: relative;
      width: 100%;
    }
      .orders-add-product-input {
        width: 100%;
        margin-bottom: 10px;
      } 
        .orders-add-product-input > p {
          width: 100%;
          margin: 0;
          margin-bottom: 4px;

          text-align: left;
          color: #000;
          font-size: 16px;
          font-family: 'PT Sans', sans-serif; 
        }
        
        .orders-add-product-input > input {
          height: 30px;
          width: calc(100% - 12px);
          display: flex;
          padding-left: 10px;

          outline: 1px solid #D0BDE2;
          border: none;
          border-radius: 4px;
          
          background-color: rgb(240,240,240);

          text-align: left;
          color: #000;
          font-size: 14px;
          font-family: 'Nunito', sans-serif; 

          -webkit-box-shadow: 0px 1px 1px 1px rgba(50,50,50,0.7);
          -moz-box-shadow: 0px 1px 1px 1px rgba(50,50,50,0.7);
          box-shadow: 0px 1px 1px 1px rgba(50,50,50,0.7);
        }
          .orders-add-product-input > input:hover,
          .orders-add-product-input > input:focus {
            outline: 1px solid #C767E5;
            background-color: #fff;
          }

      .orders-add-product-buttons {
        position: relative;
        margin-top: 50px;
        width: calc(100% - 20px);

        display: grid;
        grid-template-columns: 1fr 1fr;
      }
      
      .orders-add-product-cancel {
        cursor: pointer;
        
        height: 40px;

        display: flex;
        align-items: center;
        justify-content: center;

        border: none;
        outline: none;
        border-radius: 25px;

        background-color: transparent;

        color: #000;
        color: rgb(50,50,50);
        font-size: 14px;
        font-weight: bold;
        font-family: 'PT Sans', sans-serif;
      }


      .orders-add-product-submit-disabled {
        height: 38px;

        display: flex;
        align-items: center;
        justify-content: center;

        border: none;
        outline: none;
        border-radius: 25px;

        background-color: #ccc;

        color: #000;
        color: #fff;
        font-size: 18px;
        font-weight: bold;
        font-family: 'Source Code Pro', monospace;
        font-family: 'Nunito', sans-serif;

        -webkit-box-shadow: 0px 2px 2px 2px rgba(50,50,50,0.7);
        -moz-box-shadow: 0px 2px 2px 2px rgba(50,50,50,0.7);
        box-shadow: 0px 2px 2px 2px rgba(50,50,50,0.7);
      }

      .orders-add-product-submit {
        cursor: pointer;

        height: 38px;

        display: flex;
        align-items: center;
        justify-content: center;

        border: none;
        outline: none;
        border-radius: 25px;

        background-color: #C767E5;

        color: #000;
        color: #fff;
        font-size: 18px;
        font-weight: bold;
        font-family: 'Source Code Pro', monospace;
        font-family: 'Nunito', sans-serif;

        -webkit-box-shadow: 0px 2px 2px 2px rgba(50,50,50,0.7);
        -moz-box-shadow: 0px 2px 2px 2px rgba(50,50,50,0.7);
        box-shadow: 0px 2px 2px 2px rgba(50,50,50,0.7);
      }
        .orders-add-product-submit:active {
          transition-duration: 0.1s;

          margin-top: 23px;
          border: 3px solid #C767E5;
          color: #C767E5;
          background-color: rgb(250,250,250);

          -webkit-box-shadow: 0px 0px 0px 0px rgba(50,50,50,0.7);
          -moz-box-shadow: 0px 0px 0px 0px rgba(50,50,50,0.7);
          box-shadow: 0px 0px 0px 0px rgba(50,50,50,0.7);
        }

    
    .orders-add-product-close {
      position: absolute;
      right: 0px;
      top: 0px;

      display: flex;
      align-items: center;
      justify-content: center;

      border: none;
      outline: none;

      height: 40px;
      width: 40px;

      background-color: transparent;
    }
      .orders-add-product-close > span {
        color: #000;
        font-size: 18px;
        font-weight: bold; 
      }
      
      .orders-add-product-close:hover {
        color: #fff;
      }
}


@media (min-width: 666px) and (max-width: 961px) 
{
  .order-container {
    position: absolute;
    z-index: 555;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100vh - 70px);

    margin: 0;

    background-color: #fff;
  }

  .order-list {
    position: relative;
    margin: 0px 8px;
    padding: 0px 8px;
    height: calc(100% - 80px);

    border: 2px solid #D0BDE2;
    border-radius: 8px;

    text-align: center;

    overflow: hidden;
  }

  .order-summary {
    position: relative;
    height: 120px;
    padding-bottom: 4px;

    display: grid;
    grid-template-columns: 80% 20%;
    align-items: center;

    border-bottom: 2px solid #D0BDE2;
  } 
    .order-summary-data {
      height: 100%;
    }
      .order-summary-data > div {
        height: 40px;
        display: flex;
        align-items: center;

        font-size: 18px;
        font-family: 'Nunito', sans-serif;
      }
        .order-summary-data > div > p {
          height: 100%;
          display: flex;
          align-items: center;
          
          margin-right: 4px;
          color: #46005D;
          font-size: 18px;
          font-family: 'PT Sans', sans-serif;
          font-weight: bold;
        }

    .order-summary-status-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 4px;
      margin-left: 4px;
    }
      .order-summary-status {
        height: 26px;
        padding: 0px 10px;
        
        outline: none;
        border: 2px solid #ccc;
        border-radius: 30px;
        background-color: transparent;

        color: #ccc;
        font-size: 18px;
        font-family: 'Nunito', sans-serif;
      }
      
      .order-summary-status.active {
        cursor: default;

        border: none;
        background-color: #C767E5;
        color: #fff;
        font-weight: bold;
      }
      

    .order-summary-action {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }

    .order-summary-close {
      cursor: pointer;
      height: 50px;
      width: 150px;

      display: flex;
      align-items: center;
      justify-content: center;

      border-radius: 100px;
      border: 2px solid #245021;
      outline: none;
      background-color: #245021;

      -webkit-box-shadow: 1px 1px 2px 1px rgba(50,50,50,0.7);
      -moz-box-shadow: 1px 1px 2px 1px rgba(50,50,50,0.7);
      box-shadow: 1px 1px 2px 1px rgba(50,50,50,0.7);  
    }
      .order-summary-close > span {
        color: #46005D;
        color: #fff;
        font-size: 18px;
        font-weight: bold; 
        font-family: 'PT Sans', sans-serif;
      }


  .order-list-items {
    height: calc(100% - 130px);
    padding: 2px 0px;
    overflow-y: scroll;
  }

      
    .product-delete {
      display: flex;
      align-items: center;
      justify-content: center;

      margin: 10px 0px;
      border-left: 1px solid #ccc;
    }
    
      .product-delete > button {
        display: flex;
        align-items: center;
        justify-content: center;

        height: 17px;
        width: 17px;
        
        border: none;
        outline: none;
        border-radius: 30px;

        background-color: gray;
      }

        .product-delete > button > span {
          pointer-events: none;
          color: #fff;
          font-size: 9px; 
        }

  .orders-add-product-container {
    cursor: default;
    position: absolute;
    z-index: 777;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: rgba(50,50,50,0.5);
  }

  .orders-add-product {
    position: relative;
    height: 86%;
    width: 86%;

    padding: 10px;

    border: 2px solid #D0BDE2;
    border-radius: 10px;

    background-color: rgb(250,250,250);

    -webkit-box-shadow: 0px 0px 2px 1px rgba(50,50,50,0.7);
    -moz-box-shadow: 0px 0px 2px 1px rgba(50,50,50,0.7);
    box-shadow: 0px 0px 2px 1px rgba(50,50,50,0.7);  
  }
    .orders-add-product > h2 {
      margin: 0;
      margin-bottom: 10px;
      color: #fff;
      color: #46005D;
      color: #C767E5;
      font-size: 24px;
      font-family: 'PT Sans', sans-serif;
    }
    
    .orders-add-product-form {
      position: relative;
      width: 100%;
    }
      .orders-add-product-input {
        width: 100%;
        margin-bottom: 10px;
      } 
        .orders-add-product-input > p {
          width: 100%;
          margin: 0;
          margin-bottom: 4px;

          text-align: left;
          color: #000;
          font-size: 16px;
          font-family: 'PT Sans', sans-serif; 
        }
        
        .orders-add-product-input > input {
          height: 30px;
          width: calc(100% - 12px);
          display: flex;
          padding-left: 10px;

          outline: 1px solid #D0BDE2;
          border: none;
          border-radius: 4px;
          
          background-color: rgb(240,240,240);

          text-align: left;
          color: #000;
          font-size: 14px;
          font-family: 'Nunito', sans-serif; 

          -webkit-box-shadow: 0px 1px 1px 1px rgba(50,50,50,0.7);
          -moz-box-shadow: 0px 1px 1px 1px rgba(50,50,50,0.7);
          box-shadow: 0px 1px 1px 1px rgba(50,50,50,0.7);
        }
          .orders-add-product-input > input:hover,
          .orders-add-product-input > input:focus {
            outline: 1px solid #C767E5;
            background-color: #fff;
          }

      .orders-add-product-buttons {
        position: relative;
        margin-top: 50px;
        width: calc(100% - 20px);

        display: grid;
        grid-template-columns: 1fr 1fr;
      }
      
      .orders-add-product-cancel {
        cursor: pointer;
        
        height: 40px;

        display: flex;
        align-items: center;
        justify-content: center;

        border: none;
        outline: none;
        border-radius: 25px;

        background-color: transparent;

        color: #000;
        color: rgb(50,50,50);
        font-size: 14px;
        font-weight: bold;
        font-family: 'PT Sans', sans-serif;
      }


      .orders-add-product-submit-disabled {
        height: 38px;

        display: flex;
        align-items: center;
        justify-content: center;

        border: none;
        outline: none;
        border-radius: 25px;

        background-color: #ccc;

        color: #000;
        color: #fff;
        font-size: 18px;
        font-weight: bold;
        font-family: 'Source Code Pro', monospace;
        font-family: 'Nunito', sans-serif;

        -webkit-box-shadow: 0px 2px 2px 2px rgba(50,50,50,0.7);
        -moz-box-shadow: 0px 2px 2px 2px rgba(50,50,50,0.7);
        box-shadow: 0px 2px 2px 2px rgba(50,50,50,0.7);
      }

      .orders-add-product-submit {
        cursor: pointer;

        height: 38px;

        display: flex;
        align-items: center;
        justify-content: center;

        border: none;
        outline: none;
        border-radius: 25px;

        background-color: #C767E5;

        color: #000;
        color: #fff;
        font-size: 18px;
        font-weight: bold;
        font-family: 'Source Code Pro', monospace;
        font-family: 'Nunito', sans-serif;

        -webkit-box-shadow: 0px 2px 2px 2px rgba(50,50,50,0.7);
        -moz-box-shadow: 0px 2px 2px 2px rgba(50,50,50,0.7);
        box-shadow: 0px 2px 2px 2px rgba(50,50,50,0.7);
      }
        .orders-add-product-submit:active {
          transition-duration: 0.1s;

          margin-top: 23px;
          border: 3px solid #C767E5;
          color: #C767E5;
          background-color: rgb(250,250,250);

          -webkit-box-shadow: 0px 0px 0px 0px rgba(50,50,50,0.7);
          -moz-box-shadow: 0px 0px 0px 0px rgba(50,50,50,0.7);
          box-shadow: 0px 0px 0px 0px rgba(50,50,50,0.7);
        }

    
    .orders-add-product-close {
      position: absolute;
      right: 0px;
      top: 0px;

      display: flex;
      align-items: center;
      justify-content: center;

      border: none;
      outline: none;

      height: 40px;
      width: 40px;

      background-color: transparent;
    }
      .orders-add-product-close > span {
        color: #000;
        font-size: 18px;
        font-weight: bold; 
      }
      
      .orders-add-product-close:hover {
        color: #fff;
      }
}


@media (min-width: 961px)  {

  .order-summary {
    position: relative;

    display: grid;
    grid-template-columns: auto auto;
    align-items: center;

    padding: 0px 10px;

    border-bottom: 2px solid #D0BDE2;
  }
    .order-summary-data {
      height: 100%;
      
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      grid-gap: 4px;
    }
    .order-summary-data > div {
    }
      .order-summary-data > div h5 {
        font-weight: 800;
      }
        .order-summary-data > div h5 span {
          font-weight: 500;
        }

    .order-summary-status {
      padding-right: 8px;
      display: flex;
      grid-gap: 8px;
      border-right: 1px solid #D0BDE2;
    }
      .order-summary-status button {
        height: 40px;
        padding: 0px 16px;
      }
      

    .order-summary-action {
      display: flex;
      justify-content: flex-end;
      grid-gap: 8px;
    }
      .order-summary-action button {
        transition-duration: 0.3s;
        height: 40px;
      }
      .order-summary-action a {
        transition-duration: 0.3s;
        height: 40px;
        min-width: 40px;
      }


  .order-list-items {
    overflow-y: scroll;
    height: 100%;

    padding-right: 4px;
    padding-left: 10px;
    padding-top: 4px;

    border: 0px solid #fff;
    border-bottom: 2px solid #8c9674;
    
    text-align: center; 
  }

    .order-item {
      display: grid;
      grid-template-columns: 40px auto 40px;
      
      height: 60px;
      margin-bottom: 4px;

      border: 1px solid rgb(80,80,80);
      border-radius: 4px;
    }
      .order-item > span {
        display: flex;
        align-items: center;
        justify-content: center;

        margin: 10px 0px;
        border-right: 1px solid #ccc;

        color: #000;
        font-size: 14px;
        font-weight: bold;
        font-family: 'Nunito', sans-serif;        
      }
      .order-item-data {
        display: grid;
        grid-template-columns: 60px 80px auto 240px 120px;
        grid-gap: 10px;
        align-items: center;

        padding: 0px 10px;
      }

        .order-item-data > input[type=numeric] {
          height: 40px;

          border: none;
          border: 2px solid #8c9674;

          border-radius: 4px;
          outline: none;
          background-color: transparent;
          background-color: rgb(245,245,245);
          
          text-align: center;
          color: #fff;
          color: #000;
          font-size: 16px;
          font-family: 'Nunito', sans-serif;
          font-family: 'PT Sans', sans-serif;

          -webkit-box-shadow: 0px 1px 1px 1px rgba(50,50,50,0.7);
          -moz-box-shadow: 0px 1px 1px 1px rgba(50,50,50,0.7);
          box-shadow: 0px 1px 1px 1px rgba(50,50,50,0.7);
        }
          .order-item-data > input[type=numeric]:active, 
          .order-item-data > input[type=numeric]:focus, 
          .order-item-data > input[type=numeric]:hover {
            transition-duration: 0.1s;
            outline: none;
            
            border: 2px solid #adbf5a;
            border-radius: 4px;

            background-color: #fff;
          }

        .order-item-subdata {
          padding-left: 6px;
          border-left: 1px solid #ccc;
          text-align: left;

          color: #fff;
          color: rgb(60,60,60);
          font-size: 14px;
          font-family: 'PT Sans', sans-serif;
          font-family: 'Nunito', sans-serif;
        }

      
    .product-delete {
      display: flex;
      align-items: center;
      justify-content: center;

      margin: 10px 0px;
      border-left: 1px solid #ccc;
    }
      .product-delete > button {
        display: flex;
        align-items: center;
        justify-content: center;

        height: 17px;
        width: 17px;
        
        border: none;
        outline: none;
        border-radius: 30px;

        background-color: gray;
      }
        .product-delete > button > span {
          pointer-events: none;
          color: #fff;
          font-size: 9px; 
        }

  .orders-add-product-container {
    cursor: default;
    position: absolute;
    z-index: 777;
    height: 100%;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: rgba(50,50,50,0.5);
  }

  .orders-add-product {
    position: relative;
    height: 250px;
    width: 80%;

    padding: 20px;

    border: 4px solid #D0BDE2;
    border-radius: 10px;

    background-color: rgb(250,250,250);

    -webkit-box-shadow: 0px 0px 2px 3px rgba(50,50,50,0.7);
    -moz-box-shadow: 0px 0px 2px 3px rgba(50,50,50,0.7);
    box-shadow: 0px 0px 2px 3px rgba(50,50,50,0.7);  
  }
    .orders-add-product > h2 {
      color: #fff;
      color: rgb(30,30,30);
      font-size: 30px;
      font-family: 'PT Sans', sans-serif;
    }
    
    .orders-add-product-form {
      width: 100%;

      display: grid;
      grid-template-columns: 16% 14% auto 20%;
      grid-gap: 10px;
      align-items: center;
    }
    
      .orders-add-product-input {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
      }
        .orders-add-product-input > p {
          margin-left: 2px;
          margin-bottom: 2px;

          text-align: left;
          color: #000;
          font-size: 18px;
          font-family: 'PT Sans', sans-serif; 
        }
        .orders-add-product-input > input {
          display: flex;
          height: 36px;
          width: 90%;

          border: 3px solid #D0BDE2;
          outline: none;
          border-radius: 4px;
          
          background-color: rgb(240,240,240);

          text-align: center;
          color: #000;
          font-size: 16px;
          font-family: 'Nunito', sans-serif; 

          -webkit-box-shadow: 0px 1px 1px 1px rgba(50,50,50,0.7);
          -moz-box-shadow: 0px 1px 1px 1px rgba(50,50,50,0.7);
          box-shadow: 0px 1px 1px 1px rgba(50,50,50,0.7);
        }
          .orders-add-product-input > input:hover,
          .orders-add-product-input > input:focus {
            transition-duration: 0.1s;
            border: 3px solid #C767E5;
            background-color: #fff;

            -webkit-box-shadow: 0px 1px 1px 1px rgba(50,50,50,0.7);
            -moz-box-shadow: 0px 1px 1px 1px rgba(50,50,50,0.7);
            box-shadow: 0px 1px 1px 1px rgba(50,50,50,0.7);
          }

      .orders-add-product-cancel, .orders-add-product-cancel:focus {
        cursor: pointer;
        position: absolute;
        left: 40px;
        bottom: 20px;
        height: 40px;
        width: 150px;

        display: flex;
        align-items: center;
        justify-content: center;

        border: none;
        outline: none;
        border-radius: 25px;

        background-color: transparent;

        color: #000;
        color: rgb(50,50,50);
        font-size: 18px;
        font-weight: bold;
        font-family: 'PT Sans', sans-serif;
      }
        .orders-add-product-cancel:hover {
          border: 3px solid #C767E5;
          color: #C767E5;

          -webkit-box-shadow: 0px 2px 2px 2px rgba(50,50,50,0.7);
          -moz-box-shadow: 0px 2px 2px 2px rgba(50,50,50,0.7);
          box-shadow: 0px 2px 2px 2px rgba(50,50,50,0.7);
        }
        .orders-add-product-cancel:active {
          transition-duration: 0.1s;
          bottom: 27px;

          -webkit-box-shadow: 0px 0px 0px 0px rgba(50,50,50,0.7);
          -moz-box-shadow: 0px 0px 0px 0px rgba(50,50,50,0.7);
          box-shadow: 0px 0px 0px 0px rgba(50,50,50,0.7);
        }


      .orders-add-product-submit-disabled {
        position: absolute;
        right: 40px;
        bottom: 20px;

        height: 40px;
        width: 150px;

        display: flex;
        align-items: center;
        justify-content: center;

        border: none;
        outline: none;
        border-radius: 25px;

        background-color: #ccc;

        color: #000;
        color: #fff;
        font-size: 18px;
        font-weight: bold;
        font-family: 'Source Code Pro', monospace;
        font-family: 'Nunito', sans-serif;

        -webkit-box-shadow: 0px 2px 2px 2px rgba(50,50,50,0.7);
        -moz-box-shadow: 0px 2px 2px 2px rgba(50,50,50,0.7);
        box-shadow: 0px 2px 2px 2px rgba(50,50,50,0.7);
      }

      .orders-add-product-submit, .orders-add-product-submit:focus {
        cursor: pointer;

        position: absolute;
        right: 20px;
        bottom: 40px;

        height: 40px;
        width: 150px;

        display: flex;
        align-items: center;
        justify-content: center;

        border: none;
        outline: none;
        border-radius: 25px;

        background-color: #C767E5;

        color: #000;
        color: #fff;
        font-size: 18px;
        font-weight: bold;
        font-family: 'Source Code Pro', monospace;
        font-family: 'Nunito', sans-serif;

        -webkit-box-shadow: 0px 2px 2px 2px rgba(50,50,50,0.7);
        -moz-box-shadow: 0px 2px 2px 2px rgba(50,50,50,0.7);
        box-shadow: 0px 2px 2px 2px rgba(50,50,50,0.7);
      }
        .orders-add-product-submit:hover {
          transition-duration: 0.1s;
          border: 3px solid #C767E5;
          color: #C767E5;
          background-color: rgb(250,250,250);
        }
        .orders-add-product-submit:active {
          transition-duration: 0.1s;

          margin-top: 23px;
          border: 3px solid #C767E5;
          color: #C767E5;
          background-color: rgb(250,250,250);

          -webkit-box-shadow: 0px 0px 0px 0px rgba(50,50,50,0.7);
          -moz-box-shadow: 0px 0px 0px 0px rgba(50,50,50,0.7);
          box-shadow: 0px 0px 0px 0px rgba(50,50,50,0.7);
        }

    
    .orders-add-product-close {
      position: absolute;
      right: 0px;
      top: 0px;

      display: flex;
      align-items: center;
      justify-content: center;

      border: none;
      outline: none;

      height: 40px;
      width: 40px;

      background-color: transparent;
    }
      .orders-add-product-close > span {
        color: #000;
        font-size: 18px;
        font-weight: bold; 
      }
      
      .orders-add-product-close:hover {
        color: #fff;
      }
}