@media (max-width: 666px)  {
	.select {
		position: relative;
		width: 100%;
	}

	.select > button {
		cursor: pointer;

		height: 40px;
		width: 100%;

		outline: none;
		border: none;
		border-radius: 4px;

		background-color: rgb(245,245,245);
		background-color: transparent;
	}
		.select > button:hover {
			border: 2px solid #C767E5;
		}

	.select-items {
		position: absolute;
		left: 0;
		z-index: 999;
		width: 140%;
		max-height: 140px;
		overflow-y: auto;

		border: 1px solid #D0BDE2;
		border-radius: 10px;

	  box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.2);
	}

	.select-item {
		height: 32px;
		width: 100%;
		padding: 0px 10px;

		border: none;
		outline: none;
		background-color: transparent;

		color: #000;
	  font-size: 14px;
	  font-family: 'Nunito', sans-serif;        
	  
	  overflow: hidden;
	  text-overflow: ellipsis;
	  white-space: nowrap;
	}

		.select-item-hover, 
		.select-item-hover:focus, 
		.select-item-hover:active {
			background-color: #D0BDE2;
		}

		.select-item.active {
			outline: none;
			color: #fff;
			background-color: #C767E5;
		}
			.select-item.active:hover {
				background-color: #C767E5;
			}
}


@media (min-width: 666px) and (max-width: 961px) 
{
	.select {
		position: relative;
		width: 100%;
	}

	.selected {
		cursor: pointer;

		height: 36px;
		width: 100%;

		outline: none;
		border: none;
		border-radius: 4px;

		background-color: rgb(245,245,245);
		background-color: transparent;

		color: #000;
	  font-size: 12px;
	  font-family: 'PT Sans', sans-serif;        
	  font-family: 'Nunito', sans-serif;        
	}

		.selected:active, .selected:focus, .selected:hover {
			transition-duration: 0.1s;
			outline: none;
			
			border: 2px solid #C767E5;
			border-radius: 4px;

			background-color: #fff;
		}

	.select-items-none {
		display: none;
	}

	.select-items {
		z-index: 999;
		padding: 0;

		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		
		width: 140%;
		max-height: 140px;

		border: 1px solid #D0BDE2;
		border-radius: 10px;

		background-color: rgb(250,250,250);
		overflow-y: scroll;

		-webkit-box-shadow: 0px 1px 2px 1px rgba(50,50,50,0.7);
	  -moz-box-shadow: 0px 1px 2px 1px rgba(50,50,50,0.7);
	  box-shadow: 0px 1px 2px 1px rgba(50,50,50,0.7);
	}

	.select-items::-webkit-scrollbar {
	  -webkit-appearance: none;
	  width: 7px;
	}

	.select-items::-webkit-scrollbar-thumb {
	  border-radius: 4px;
	  background-color: rgba(0, 0, 0, .5);
	  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
	}

	.select-item, 
	.select-item:focus, 
	.select-item:active {
		display: flex;
		align-items: center;
		justify-content: center;

		height: 32px;
		width: 100%;
		padding: 0px 10px;

		border: none;
		outline: none;

		background-color: transparent;

		color: #000;
	  font-size: 14px;
	  font-family: 'Nunito', sans-serif;        
	  
	  overflow: hidden;
	  text-overflow: ellipsis;
	  white-space: nowrap;
	}

		.select-item-hover, 
		.select-item-hover:focus, 
		.select-item-hover:active {
			display: flex;
			align-items: center;
			justify-content: center;

			height: 32px;
			width: 100%;
			padding: 0px 10px;

			list-style-type: none;
			border: none;
			outline: none;

			background-color: transparent;
			background-color: #D0BDE2;

			color: #000;
		  font-size: 14px;
		  font-family: 'Nunito', sans-serif;        
		  
		  overflow: hidden;
		  text-overflow: ellipsis;
		  white-space: nowrap;

		}

		.select-item.active {
			outline: none;
			color: #fff;
			background-color: #C767E5;
		}
			.select-item.active:hover {
				background-color: #C767E5;
				}
}

@media (min-width: 961px)  {
	.select {
		position: relative;
		width: 100%;
	}

	.select button {
		height: 40px;
		width: 100%;
	}

	.select-items-none {
		display: none;
	}

	.select-items {
		z-index: 999;
		padding: 0;

		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		
		width: 140%;
		max-height: 140px;

		border: 1px solid #D0BDE2;
		border-radius: 10px;

		background-color: rgb(250,250,250);
		overflow-y: scroll;

		-webkit-box-shadow: 0px 1px 2px 1px rgba(50,50,50,0.7);
	  -moz-box-shadow: 0px 1px 2px 1px rgba(50,50,50,0.7);
	  box-shadow: 0px 1px 2px 1px rgba(50,50,50,0.7);
	}
	.select-items::-webkit-scrollbar {
	  -webkit-appearance: none;
	  width: 7px;
	}
	.select-items::-webkit-scrollbar-thumb {
	  border-radius: 4px;
	  background-color: rgba(0, 0, 0, .5);
	  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
	}

	.select-item, 
	.select-item:focus, 
	.select-item:active {
		display: flex;
		align-items: center;
		justify-content: center;

		height: 32px;
		width: 100%;
		padding: 0px 10px;

		border: none;
		outline: none;

		background-color: transparent;
	  
	  overflow: hidden;
	  text-overflow: ellipsis;
	  white-space: nowrap;
	}

		.select-item-hover, 
		.select-item-hover:focus, 
		.select-item-hover:active {
			display: flex;
			align-items: center;
			justify-content: center;

			height: 32px;
			width: 100%;
			padding: 0px 10px;

			list-style-type: none;
			border: none;
			outline: none;

			background-color: transparent;
			background-color: #D0BDE2;
		  
		  overflow: hidden;
		  text-overflow: ellipsis;
		  white-space: nowrap;

		}

		.select-item.active {
			outline: none;
			color: #fff;
			background-color: #C767E5;
		}
			.select-item.active:hover {
				background-color: #C767E5;
			}
}