@media (max-width: 666px) {
  .support {
    position: relative;
    padding: 10px;
    height: calc(100vh - 168px);

    display: flex;
    justify-content: center;
  }
    .support > img {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      height: 60vh;
      object-fit: cover;
    }
}


@media (min-width: 666px) and (max-width: 961px) 
{
}

@media (min-width: 961px)  {
.support {
    position: relative;
    padding: 10px;
    height: calc(100vh - 168px);

    display: flex;
    justify-content: center;
  }
    .support > img {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      height: 60vh;
      object-fit: cover;
    }
}
