@media (max-width: 666px)  {
	.select-p {
		position: relative;

		width: 100%;
	}
		/*.select-p.active {
			grid-column: 1/5;
		}*/

	.selected-p-note {
		display: flex;
		height: 100%;
		width: 100%;
		padding: 4px;

		outline: none;
		border: none;
		border: 1px solid #D0BDE2;
		border-radius: 4px;
		background-color: transparent;
	  
  	overflow-wrap: normal;
	}

	.select-p input {
		width: 240px;
	  white-space: pre-wrap;
	}

	.select-p-items-none {
		display: none;
	}
	.select-p-items {
		z-index: 999;
		padding: 0;

		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		
		width: 110%;
		max-height: 140px;

		border: 2px solid #D0BDE2;
		border-radius: 10px;

		background-color: rgb(250,250,250);
		overflow-y: scroll;

		-webkit-box-shadow: 0px 1px 2px 1px rgba(50,50,50,0.7);
	  -moz-box-shadow: 0px 1px 2px 1px rgba(50,50,50,0.7);
	  box-shadow: 0px 1px 2px 1px rgba(50,50,50,0.7);
	}
	.select-p-items::-webkit-scrollbar {
	  -webkit-appearance: none;
	  width: 7px;
	}
	.select-p-items::-webkit-scrollbar-thumb {
	  border-radius: 4px;
	  background-color: rgba(0, 0, 0, .5);
	  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
	}

	.select-p-item {
		display: flex;
		align-items: center;
		justify-content: center;

		height: 32px;
		width: 100%;
		padding: 0px 10px;

		border: none;
		outline: none;

		background-color: transparent;
	  
	  overflow: hidden;
	  text-overflow: ellipsis;
	  white-space: nowrap;
	}
		.select-p-item-hover {
			cursor: pointer;
			
			display: flex;
			align-items: center;
			justify-content: center;

			height: 32px;
			width: 100%;
			padding: 0px 10px;

			border: none;
			outline: none;

			background-color: #D0BDE2;
		  
		  overflow: hidden;
		  text-overflow: ellipsis;
		  white-space: nowrap;

		}
		.select-p-item.active {
			outline: none;
			color: #fff;
			background-color: #C767E5;
		}
}


@media (min-width: 666px) and (max-width: 961px) 
{
	.select-p {
		position: relative;
		height: 100%;
		width: 100%;

		display: flex;
		align-items: center;
		justify-content: center;
	}

	.selected-p-note {
		cursor: text;

		width: 100%;
		height: 40px;
		padding-left: 4px;

		outline: none;
		border: none;
		border-radius: 4px;

		color: #000;
	  font-size: 14px;
	  font-family: 'PT Sans', sans-serif;
	  font-family: 'Nunito', sans-serif;

	  
  	overflow-wrap: normal;
	}

	.selected-p {
		cursor: text;
		
		height: 30px;
		width: 100%;
		
		padding-left: 4px;

		outline: none;
		border: none;

		background-color: transparent;

		color: #000;
	  font-size: 12px;
	  font-family: 'PT Sans', sans-serif;
	  font-family: 'Nunito', sans-serif;

	  white-space: wrap;
	}

		.selected-p:active, 
		.selected-p:focus, 
		.selected-p:hover {
			transition-duration: 0.1s;
			outline: none;
			
			border: 2px solid #C767E5;
			border-radius: 4px;

			background-color: #fff;
		}

	.select-p-items-none {
		display: none;
	}

	.select-p-items {
		z-index: 999;
		padding: 0;

		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		
		width: 110%;
		max-height: 140px;

		border: 2px solid #D0BDE2;
		border-radius: 10px;

		background-color: rgb(250,250,250);
		overflow-y: scroll;

		-webkit-box-shadow: 0px 1px 2px 1px rgba(50,50,50,0.7);
	  -moz-box-shadow: 0px 1px 2px 1px rgba(50,50,50,0.7);
	  box-shadow: 0px 1px 2px 1px rgba(50,50,50,0.7);
	}

	.select-p-items::-webkit-scrollbar {
	  -webkit-appearance: none;
	  width: 7px;
	}

	.select-p-items::-webkit-scrollbar-thumb {
	  border-radius: 4px;
	  background-color: rgba(0, 0, 0, .5);
	  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
	}

	.select-p-item {
		display: flex;
		align-items: center;
		justify-content: center;

		height: 32px;
		width: 100%;
		padding: 0px 10px;

		border: none;
		outline: none;

		background-color: transparent;

		color: #000;
	  font-size: 14px;
	  font-family: 'Nunito', sans-serif;        
	  
	  overflow: hidden;
	  text-overflow: ellipsis;
	  white-space: nowrap;
	}

		.select-p-item-hover {
			cursor: pointer;
			
			display: flex;
			align-items: center;
			justify-content: center;

			height: 32px;
			width: 100%;
			padding: 0px 10px;

			border: none;
			outline: none;

			background-color: #D0BDE2;

			color: #000;
		  font-size: 14px;
		  font-family: 'Nunito', sans-serif;        
		  
		  overflow: hidden;
		  text-overflow: ellipsis;
		  white-space: nowrap;

		}

		.select-p-item.active {
			outline: none;
			color: #fff;
			background-color: #C767E5;
		}
}

@media (min-width: 961px)  {
	.select-p {
		position: relative;

		width: 100%;
	}
		/*.select-p.active {
			grid-column: 1/5;
		}*/

	.selected-p-note {
		display: flex;
		height: 100%;
		width: 100%;
		padding: 4px;

		outline: none;
		border: none;
		border: 1px solid #D0BDE2;
		border-radius: 4px;
		background-color: transparent;
	  
  	overflow-wrap: normal;
	}

	.select-p input {
		width: 100%;
	  white-space: pre-wrap;
	}

	.select-p-items-none {
		display: none;
	}
	.select-p-items {
		z-index: 999;
		padding: 0;

		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		
		width: 110%;
		max-height: 140px;

		border: 2px solid #D0BDE2;
		border-radius: 10px;

		background-color: rgb(250,250,250);
		overflow-y: scroll;

		-webkit-box-shadow: 0px 1px 2px 1px rgba(50,50,50,0.7);
	  -moz-box-shadow: 0px 1px 2px 1px rgba(50,50,50,0.7);
	  box-shadow: 0px 1px 2px 1px rgba(50,50,50,0.7);
	}
	.select-p-items::-webkit-scrollbar {
	  -webkit-appearance: none;
	  width: 7px;
	}
	.select-p-items::-webkit-scrollbar-thumb {
	  border-radius: 4px;
	  background-color: rgba(0, 0, 0, .5);
	  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
	}

	.select-p-item {
		display: flex;
		align-items: center;
		justify-content: center;

		height: 32px;
		width: 100%;
		padding: 0px 10px;

		border: none;
		outline: none;

		background-color: transparent;
	  
	  overflow: hidden;
	  text-overflow: ellipsis;
	  white-space: nowrap;
	}
		.select-p-item-hover {
			cursor: pointer;
			
			display: flex;
			align-items: center;
			justify-content: center;

			height: 32px;
			width: 100%;
			padding: 0px 10px;

			border: none;
			outline: none;

			background-color: #D0BDE2;
		  
		  overflow: hidden;
		  text-overflow: ellipsis;
		  white-space: nowrap;

		}
		.select-p-item.active {
			outline: none;
			color: #fff;
			background-color: #C767E5;
		}
}