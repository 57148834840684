
@media (max-width: 666px)  {

}

@media (min-width: 666px) and (max-width: 961px) 
{
}

@media (min-width: 961px)  {
  .chart {
    border-radius: 16px;
    box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.1);
  }

  .title {
    margin-bottom: 20px;
    padding: 20px;
  }

  .chartGrid {
    stroke: rgb(228, 225, 225);
  }
}

