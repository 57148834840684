@media (max-width: 666px) {
  
  .add-item {
    position: relative;
    width: calc(100% - 32px);
    padding: 16px;

    border: 2px solid #D0BDE2;
    border-radius: 16px;

    box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.2);
  }
    .add-item > h2 {
      margin: 0;
      margin-bottom: 20px;
      color: #222;
      font-weight: bold;
      font-family: 'PT Sans', sans-serif;
    }
    
    .add-item-form {
      position: relative;
      width: 100%;
    }
      .add-item-input {
        width: 100%;
        margin-bottom: 16px;
      } 
        .add-item-input > h5 {
          margin-bottom: 6px;
        }
        
        .add-item-input > input {
          height: 40px;
          width: 40%;
          padding: 0px 8px;
        }

      .add-item-buttons {
        position: relative;
        margin-top: 50px;
        width: calc(100% - 20px);

        display: grid;
        grid-template-columns: 1fr 1fr;
      }
      
      .add-item-cancel {
        cursor: pointer;
        
        height: 40px;

        display: flex;
        align-items: center;
        justify-content: center;

        border: none;
        outline: none;
        border-radius: 25px;

        background-color: transparent;

        color: #000;
        color: rgb(50,50,50);
        font-size: 14px;
        font-weight: bold;
        font-family: 'PT Sans', sans-serif;
      }


      .add-item-submit-disabled {
        height: 38px;

        display: flex;
        align-items: center;
        justify-content: center;

        border: none;
        outline: none;
        border-radius: 25px;

        background-color: #ccc;

        color: #000;
        color: #fff;
        font-size: 18px;
        font-weight: bold;
        font-family: 'Source Code Pro', monospace;
        font-family: 'Nunito', sans-serif;

        -webkit-box-shadow: 0px 2px 2px 2px rgba(50,50,50,0.7);
        -moz-box-shadow: 0px 2px 2px 2px rgba(50,50,50,0.7);
        box-shadow: 0px 2px 2px 2px rgba(50,50,50,0.7);
      }

      .add-item-submit {
        cursor: pointer;

        height: 38px;

        display: flex;
        align-items: center;
        justify-content: center;

        border: none;
        outline: none;
        border-radius: 25px;

        background-color: #C767E5;

        color: #000;
        color: #fff;
        font-size: 18px;
        font-weight: bold;
        font-family: 'Source Code Pro', monospace;
        font-family: 'Nunito', sans-serif;

        -webkit-box-shadow: 0px 2px 2px 2px rgba(50,50,50,0.7);
        -moz-box-shadow: 0px 2px 2px 2px rgba(50,50,50,0.7);
        box-shadow: 0px 2px 2px 2px rgba(50,50,50,0.7);
      }
        .add-item-submit:active {
          transition-duration: 0.1s;

          margin-top: 23px;
          border: 3px solid #C767E5;
          color: #C767E5;
          background-color: rgb(250,250,250);

          -webkit-box-shadow: 0px 0px 0px 0px rgba(50,50,50,0.7);
          -moz-box-shadow: 0px 0px 0px 0px rgba(50,50,50,0.7);
          box-shadow: 0px 0px 0px 0px rgba(50,50,50,0.7);
        }

    
    .add-item-close {
      position: absolute;
      right: 0px;
      top: 0px;

      display: flex;
      align-items: center;
      justify-content: center;

      border: none;
      outline: none;

      height: 40px;
      width: 40px;

      background-color: transparent;
    }
      .add-item-close > span {
        color: #000;
        font-size: 18px;
        font-weight: bold; 
      }
      
      .add-item-close:hover {
        color: #fff;
      }
}


@media (min-width: 666px) and (max-width: 961px) 
{

  .add-item-container {
    cursor: default;
    position: absolute;
    z-index: 777;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: rgba(50,50,50,0.5);
  }

  .add-item {
    position: relative;
    height: 86%;
    width: 86%;

    padding: 10px;

    border: 2px solid #D0BDE2;
    border-radius: 10px;

    background-color: rgb(250,250,250);

    -webkit-box-shadow: 0px 0px 2px 1px rgba(50,50,50,0.7);
    -moz-box-shadow: 0px 0px 2px 1px rgba(50,50,50,0.7);
    box-shadow: 0px 0px 2px 1px rgba(50,50,50,0.7);  
  }
    .add-item > h2 {
      margin: 0;
      margin-bottom: 10px;
      color: #fff;
      color: #46005D;
      color: #C767E5;
      font-size: 24px;
      font-family: 'PT Sans', sans-serif;
    }
    
    .add-item-form {
      position: relative;
      width: 100%;
    }
      .add-item-input {
        width: 100%;
        margin-bottom: 10px;
      } 
        .add-item-input > p {
          width: 100%;
          margin: 0;
          margin-bottom: 4px;

          text-align: left;
          color: #000;
          font-size: 16px;
          font-family: 'PT Sans', sans-serif; 
        }
        
        .add-item-input > input {
          height: 30px;
          width: calc(100% - 12px);
          display: flex;
          padding-left: 10px;

          outline: 1px solid #D0BDE2;
          border: none;
          border-radius: 4px;
          
          background-color: rgb(240,240,240);

          text-align: left;
          color: #000;
          font-size: 14px;
          font-family: 'Nunito', sans-serif; 

          -webkit-box-shadow: 0px 1px 1px 1px rgba(50,50,50,0.7);
          -moz-box-shadow: 0px 1px 1px 1px rgba(50,50,50,0.7);
          box-shadow: 0px 1px 1px 1px rgba(50,50,50,0.7);
        }
          .add-item-input > input:hover,
          .add-item-input > input:focus {
            outline: 1px solid #C767E5;
            background-color: #fff;
          }

      .add-item-buttons {
        position: relative;
        margin-top: 50px;
        width: calc(100% - 20px);

        display: grid;
        grid-template-columns: 1fr 1fr;
      }
      
      .add-item-cancel {
        cursor: pointer;
        
        height: 40px;

        display: flex;
        align-items: center;
        justify-content: center;

        border: none;
        outline: none;
        border-radius: 25px;

        background-color: transparent;

        color: #000;
        color: rgb(50,50,50);
        font-size: 14px;
        font-weight: bold;
        font-family: 'PT Sans', sans-serif;
      }


      .add-item-submit-disabled {
        height: 38px;

        display: flex;
        align-items: center;
        justify-content: center;

        border: none;
        outline: none;
        border-radius: 25px;

        background-color: #ccc;

        color: #000;
        color: #fff;
        font-size: 18px;
        font-weight: bold;
        font-family: 'Source Code Pro', monospace;
        font-family: 'Nunito', sans-serif;

        -webkit-box-shadow: 0px 2px 2px 2px rgba(50,50,50,0.7);
        -moz-box-shadow: 0px 2px 2px 2px rgba(50,50,50,0.7);
        box-shadow: 0px 2px 2px 2px rgba(50,50,50,0.7);
      }

      .add-item-submit {
        cursor: pointer;

        height: 38px;

        display: flex;
        align-items: center;
        justify-content: center;

        border: none;
        outline: none;
        border-radius: 25px;

        background-color: #C767E5;

        color: #000;
        color: #fff;
        font-size: 18px;
        font-weight: bold;
        font-family: 'Source Code Pro', monospace;
        font-family: 'Nunito', sans-serif;

        -webkit-box-shadow: 0px 2px 2px 2px rgba(50,50,50,0.7);
        -moz-box-shadow: 0px 2px 2px 2px rgba(50,50,50,0.7);
        box-shadow: 0px 2px 2px 2px rgba(50,50,50,0.7);
      }
        .add-item-submit:active {
          transition-duration: 0.1s;

          margin-top: 23px;
          border: 3px solid #C767E5;
          color: #C767E5;
          background-color: rgb(250,250,250);

          -webkit-box-shadow: 0px 0px 0px 0px rgba(50,50,50,0.7);
          -moz-box-shadow: 0px 0px 0px 0px rgba(50,50,50,0.7);
          box-shadow: 0px 0px 0px 0px rgba(50,50,50,0.7);
        }

    
    .add-item-close {
      position: absolute;
      right: 0px;
      top: 0px;

      display: flex;
      align-items: center;
      justify-content: center;

      border: none;
      outline: none;

      height: 40px;
      width: 40px;

      background-color: transparent;
    }
      .add-item-close > span {
        color: #000;
        font-size: 18px;
        font-weight: bold; 
      }
      
      .add-item-close:hover {
        color: #fff;
      }
}


@media (min-width: 961px)  {

  .add-item-container {
    cursor: default;
    position: absolute;
    z-index: 777;
    height: 100%;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: rgba(50,50,50,0.5);
  }

  .add-item {
    position: relative;

    padding: 20px 40px;
    border-radius: 16px;

    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.1);  
  }
    .add-item > h2 {
      text-align: left;
      margin-bottom: 20px;
      font-weight: bold;
      font-family: 'Nunito', sans-serif;
    }
    
    .add-item-form {
      width: 100%;

      display: flex;
      flex-direction: column;
      grid-gap: 16px;
      align-items: center;
    }
    
      .add-item-input {
        display: grid;
        grid-template-columns: 80px 300px;
        align-items: center;
        width: 100%;
      }
        .add-item-input > h5 {
          text-align: left;
          font-weight: bold;
          font-family: 'Nunit', sans-serif; 
        }
        
      .add-item-buttons {
        width: 100%;
        margin-top: 20px;
        display: flex;
        justify-content: flex-end;
      }
        .add-item-buttons button {
          height: 40px;
          padding: 0px 20px;
        }
        

    
    .add-item-close {
      cursor: pointer;
      position: absolute;
      right: 0px;
      top: 0px;
      margin: 20px;

      border: none;
      outline: none;
      background-color: transparent;
    }
      .add-item-close > i {
        color: #fff;
        font-size: 24px;
      }
        .add-item-close:hover > i {
          color: #C767E5;
        }
}