@media (max-width: 666px) {
  .auth-container {  
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    overflow: hidden;
  }

  .auth-video { 
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  }
    .auth-video video { 
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

  .auth-logo-container {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    margin: 16px;
    height: 60px;

    border-radius: 50px;

    background-color: #ffffffaa;
    backdrop-filter: blur(8px);
  }
    .auth-logo-container > img {
      height: 60px;
    }


  #auth-isloading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
    width: 500px;
    height: 60vh;

    border-radius: 5px;

    background-color: rgba(250,250,250,0.8);
  }

    #auth-isloading > h1 {
      display: flex;
      align-items: center;
      justify-content: center;
      
      height: 100%;

      font-family: 'Libre Baskerville', sans-serif;
      font-family: 'Montserrat', sans-serif;
      font-size: 20px;
    }

  .auth-form-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100vw - 40px);
    padding: 8px;
    padding-top: 16px;

    border-radius: 12px;
    background-color: #ffffffaa;
    backdrop-filter: blur(8px);
    box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.2);
  }
    .auth-form-container > h1 {
      font-family: 'Libre Baskerville', sans-serif;
      font-family: 'Montserrat', sans-serif;
      font-size: 20px;
    }
    .auth-form-container > p {
      margin-left: 4px;
      margin-top: 6px;
      margin-bottom: 20px;

      color: #374258;
      font-family: 'Libre Baskerville', sans-serif;
      font-family: 'Montserrat', sans-serif;
      font-size: 12px;
    }
      .auth-form-container > p > span {
        color: #830004;
        font-weight: bold;
      }

      .auth-form-two {
        width: 100%;
        height: 100%;
      }

      .auth-form-input-container {   
        width: 100%;
        margin-top: 10px;
        display: flex;
        flex-direction: column;
      }
        .auth-form-label {
          padding-left: 4px;

          font-family: 'Libre Baskerville', sans-serif;
          font-family: 'Montserrat', sans-serif;
          font-size: 14px;
        }

        .auth-form-input {
          display: flex;
          
          padding-left: 14px;
          margin-top: 6px;

          outline: none;
          border: none;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
          border-bottom-left-radius: 2px;
          border-bottom-right-radius: 2px;
          border-bottom: 2px solid rgb(173, 185, 201);

          text-overflow: ellipsis;
          overflow: hidden; 

          background-color: rgba(250,250,250,0.8);

          line-height: 40px;
          font-family: 'Libre Baskerville', sans-serif;
          font-family: 'Montserrat', sans-serif;
          font-size: 12px;
        }
        
      #auth-form-phonenumber-container {
        display: grid;
        grid-template-columns: 80px auto;
        grid-gap: 0px;

        height: 40px;

        margin-top: 6px;

        overflow: hidden;

        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        border-bottom-left-radius: 2px;
        border-bottom-right-radius: 2px;
        overflow: hidden;

        border-bottom: 2px solid rgb(173, 185, 201);
      }

      #auth-form-phonenumber-container:hover {
        border-bottom: 2px solid rgb(131,0,4);
        border-bottom: 2px solid #4B6B3B;
      }

        #auth-form-phonenumber-container > select {
          width: auto;
          height: 100%;

          text-align: center;
          text-align-last: center;

          border: none;
          background-color: rgb(131,0,4);

          color: #fff;
          font-family: 'Libre Baskerville', sans-serif;
          font-family: 'Montserrat', sans-serif;
          font-size: 12px;
        }

        .auth-form-phonenumber-input {
          display: flex;

          height: 100%;
          width: 100%;

          padding-left: 10px;
          
          outline: none;
          border: none;

          text-overflow: ellipsis;
          overflow: hidden; 

          background-color: transparent;
          background-color: rgba(250,250,250,0.8);

          letter-spacing: 1px;
          font-size: 12px;
          font-family: 'Libre Baskerville', sans-serif;
          font-family: 'Montserrat', sans-serif;
        }
        

    .auth-form-buttons-container {
      width: 100%;
      margin-top: 20px;
    }
      .auth-form-buttons-container > p {
        color: #374258;

        font-size: 12px;
        font-family: 'Libre Baskerville', sans-serif;
        font-family: 'Montserrat', sans-serif;
      }
        .auth-form-buttons-container > p > a {
          cursor: pointer;
          color: rgb(131,0,4);
        }
          .auth-form-buttons-container > p > a:hover {
            border-bottom: 1px solid rgb(131,0,4);
            border-bottom: 1px solid #4B6B3B;
          }

      .auth-form-buttons-container > a {
        cursor: pointer;
        color: #374258;

        font-size: 12px;
        font-family: 'Libre Baskerville', sans-serif;
        font-family: 'Montserrat', sans-serif;
      }

      .auth-form-buttons-container > a:hover {
        color: rgb(131,0,4);
        color: #4B6B3B;
      }

      .auth-form-buttons-container button {
        height: 40px;
        padding: 0px 16px;
      }


    #auth-form-verify-code {
      display: grid;
      grid-template-columns: auto 120px;
      grid-gap: 10px;

      width: 100%;
    }

      #auth-form-verify-code > input[type=numeric] {
        height: 40px;
        width: 100%;
        padding-left: 14px;

        text-overflow: ellipsis;
        overflow: hidden; 

        background-color: rgba(250,250,250,0.8);

        font-family: 'Libre Baskerville', sans-serif;
        font-family: 'Montserrat', sans-serif;
        font-size: 12px;
      }
      
        #auth-form-verify-code > input[type=numeric]:hover {
          border-bottom: 2px solid rgb(131,0,4);
          border-bottom: 2px solid #4B6B3B;
        }

      #auth-form-verify-code > button {
        cursor: pointer;
        height: 100%;
        width: 120px;

        border: 2px solid rgb(131,0,4);
        border-radius: 4px;
        background-color: transparent;

        text-align: center;
        color: rgb(131,0,4);
        font-size: 13px;
        font-family: 'Libre Baskerville', sans-serif;
        font-family: 'Montserrat', sans-serif;
      }

        #auth-form-verify-code > button:hover {
          border: 2px solid rgba(131,0,4,0.7);
          border: 2px solid #4B6B3B;
          color: rgba(131,0,4,0.7);
          color: #4B6B3B;
        }


    .auth-form-forgot-password {
      margin-top: 5px;

      color: #374258;

      font-size: 12px;
      font-family: 'Libre Baskerville', sans-serif;
      font-family: 'Montserrat', sans-serif;
    }
    
      .auth-form-forgot-password > a {
        cursor: pointer;
        color: rgb(131,0,4);
      }
        
        .auth-form-forgot-password > a:hover {
          border-bottom: 1px solid rgb(131,0,4);
          border-bottom: 1px solid #4B6B3B;
        }
}

@media (min-width: 666px) and (max-width: 961px) 
{
  .auth-container {  
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    overflow: hidden;
  }

  .auth-video { 
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  }
    .auth-video video { 
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

  .auth-logo-container {
    position: absolute;
    top: 0;
    left: 0;
    margin: 10px;

    border-radius: 50px;

    background-color: rgb(250,250,250);
  }

  .auth-logo-container > img {
    height: 70px;
  }


  #auth-isloading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
    width: 500px;
    height: 60vh;

    border-radius: 5px;

    background-color: rgba(250,250,250,0.8);
  }

    #auth-isloading > h1 {
      display: flex;
      align-items: center;
      justify-content: center;
      
      height: 100%;

      font-family: 'Libre Baskerville', sans-serif;
      font-family: 'Montserrat', sans-serif;
      font-size: 20px;
    }

  .auth-form-container {
    position: absolute;
    top: 50%;
    margin-top: -150px;
    left: 40px;
    width: calc(100vw - 100px);
    padding: 10px;

    border-radius: 5px;

    background-color: rgba(250,250,250,0.8);
  }
    .auth-form-container > h1 {
      font-family: 'Libre Baskerville', sans-serif;
      font-family: 'Montserrat', sans-serif;
      font-size: 20px;
    }
    .auth-form-container > p {
      margin-left: 4px;
      margin-top: 6px;
      margin-bottom: 20px;

      color: #374258;
      font-family: 'Libre Baskerville', sans-serif;
      font-family: 'Montserrat', sans-serif;
      font-size: 12px;
    }
      .auth-form-container > p > span {
        color: #830004;
        font-weight: bold;
      }

      .auth-form-two {
        width: 100%;
        height: 100%;
      }

      .auth-form-input-container {   
        width: 100%;
        margin-top: 10px;
        display: flex;
        flex-direction: column;   
      }
        .auth-form-label {
          padding-left: 4px;

          font-family: 'Libre Baskerville', sans-serif;
          font-family: 'Montserrat', sans-serif;
          font-size: 14px;
        }

        .auth-form-input {
          display: flex;
          
          padding-left: 14px;
          margin-top: 6px;

          outline: none;
          border: none;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
          border-bottom-left-radius: 2px;
          border-bottom-right-radius: 2px;
          border-bottom: 2px solid rgb(173, 185, 201);

          text-overflow: ellipsis;
          overflow: hidden; 

          background-color: rgba(250,250,250,0.8);

          line-height: 40px;
          font-family: 'Libre Baskerville', sans-serif;
          font-family: 'Montserrat', sans-serif;
          font-size: 12px;
        }
        
      #auth-form-phonenumber-container {
        display: grid;
        grid-template-columns: 80px auto;
        grid-gap: 0px;

        height: 40px;

        margin-top: 6px;

        overflow: hidden;

        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        border-bottom-left-radius: 2px;
        border-bottom-right-radius: 2px;
        overflow: hidden;

        border-bottom: 2px solid rgb(173, 185, 201);
      }

      #auth-form-phonenumber-container:hover {
        border-bottom: 2px solid rgb(131,0,4);
        border-bottom: 2px solid #4B6B3B;
      }

        #auth-form-phonenumber-container > select {
          width: auto;
          height: 100%;

          text-align: center;
          text-align-last: center;

          border: none;
          background-color: rgb(131,0,4);

          color: #fff;
          font-family: 'Libre Baskerville', sans-serif;
          font-family: 'Montserrat', sans-serif;
          font-size: 12px;
        }

        .auth-form-phonenumber-input {
          display: flex;

          height: 100%;
          width: 100%;

          padding-left: 10px;
          
          outline: none;
          border: none;

          text-overflow: ellipsis;
          overflow: hidden; 

          background-color: transparent;
          background-color: rgba(250,250,250,0.8);

          letter-spacing: 1px;
          font-size: 12px;
          font-family: 'Libre Baskerville', sans-serif;
          font-family: 'Montserrat', sans-serif;
        }
        

    .auth-form-buttons-container {
      width: 100%;
      margin-top: 20px;
    }
      .auth-form-buttons-container > p {
        color: #374258;

        font-size: 12px;
        font-family: 'Libre Baskerville', sans-serif;
        font-family: 'Montserrat', sans-serif;
      }
        .auth-form-buttons-container > p > a {
          cursor: pointer;
          color: rgb(131,0,4);
        }
          .auth-form-buttons-container > p > a:hover {
            border-bottom: 1px solid rgb(131,0,4);
            border-bottom: 1px solid #4B6B3B;
          }

      .auth-form-buttons-container > a {
        cursor: pointer;
        color: #374258;

        font-size: 12px;
        font-family: 'Libre Baskerville', sans-serif;
        font-family: 'Montserrat', sans-serif;
      }

      .auth-form-buttons-container > a:hover {
        color: rgb(131,0,4);
        color: #4B6B3B;
      }

      .auth-form-button {
        float: right;
        cursor: pointer;
        height: 40px;
        padding: 0px 10px;

        border: none;
        border-radius: 4px;

        background-color: #4B6B3B;

        color: rgb(250,250,250);

        font-size: 13px;
        font-family: 'Libre Baskerville', sans-serif;
        font-family: 'Montserrat', sans-serif;
      }

        .auth-form-button-disabled {
          height: 40px;
          width: 120px;

          border: 1px solid #374258;
          border-radius: 4px;

          background-color: #e7e3ee;

          color: #374258;
          font-size: 13px;
          font-family: 'Libre Baskerville', sans-serif;
          font-family: 'Montserrat', sans-serif;
        }


    #auth-form-verify-code {
      display: grid;
      grid-template-columns: auto 120px;
      grid-gap: 10px;

      width: 100%;
    }

      #auth-form-verify-code > input[type=numeric] {
        height: 40px;
        width: 100%;
        padding-left: 14px;

        text-overflow: ellipsis;
        overflow: hidden; 

        background-color: rgba(250,250,250,0.8);

        font-family: 'Libre Baskerville', sans-serif;
        font-family: 'Montserrat', sans-serif;
        font-size: 12px;
      }
      
        #auth-form-verify-code > input[type=numeric]:hover {
          border-bottom: 2px solid rgb(131,0,4);
          border-bottom: 2px solid #4B6B3B;
        }

      #auth-form-verify-code > button {
        cursor: pointer;
        height: 100%;
        width: 120px;

        border: 2px solid rgb(131,0,4);
        border-radius: 4px;
        background-color: transparent;

        text-align: center;
        color: rgb(131,0,4);
        font-size: 13px;
        font-family: 'Libre Baskerville', sans-serif;
        font-family: 'Montserrat', sans-serif;
      }

        #auth-form-verify-code > button:hover {
          border: 2px solid rgba(131,0,4,0.7);
          border: 2px solid #4B6B3B;
          color: rgba(131,0,4,0.7);
          color: #4B6B3B;
        }


    .auth-form-forgot-password {
      margin-top: 5px;

      color: #374258;

      font-size: 12px;
      font-family: 'Libre Baskerville', sans-serif;
      font-family: 'Montserrat', sans-serif;
    }
    
      .auth-form-forgot-password > a {
        cursor: pointer;
        color: rgb(131,0,4);
      }
        
        .auth-form-forgot-password > a:hover {
          border-bottom: 1px solid rgb(131,0,4);
          border-bottom: 1px solid #4B6B3B;
        }
}


@media (min-width: 961px)  {
  .auth-container {  
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

    overflow: hidden;
  }

  .auth-video { 
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  }
    .auth-video video { 
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

  .auth-logo-container {
    position: absolute;
    right: 0px;
    bottom: 0px;
    
    margin: 20px 40px;
    padding-right: 8px;

    border-radius: 50px;

    background-color: #ffffffaa;
    backdrop-filter: blur(8px);
    box-shadow: 0px 6px 8px 0px rgba(0,0,0,0.2);
  }
    .auth-logo-container > img {
      height: 70px;
    }


  #auth-isloading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
    width: 500px;
    height: 60vh;

    border-radius: 5px;

    background-color: rgba(250,250,250,0.8);
  }

    #auth-isloading > h1 {
      display: flex;
      align-items: center;
      justify-content: center;
      
      height: 100%;

      font-family: 'Libre Baskerville', sans-serif;
      font-family: 'Montserrat', sans-serif;
      font-size: 20px;
    }

  .auth-form-container {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 400px;
    padding: 20px 40px;

    transform: translate(-50%, -50%);

    border-radius: 20px;
    background-color: #ffffffaa;
    backdrop-filter: blur(8px);
    box-shadow: 0px 6px 8px 0px rgba(0,0,0,0.2);
  }

    .auth-form-container > h1 {
      margin-top: 30px;

      font-family: 'Libre Baskerville', sans-serif;
      font-family: 'Montserrat', sans-serif;
      font-size: 20px;
    }

    .auth-form-container > p {
      margin-left: 4px;
      margin-top: 6px;
      margin-bottom: 20px;

      color: #374258;
      font-family: 'Libre Baskerville', sans-serif;
      font-family: 'Montserrat', sans-serif;
      font-size: 12px;
    }

    .auth-form-container > p > span {
      color: #830004;
      font-weight: bold;
    }

      .auth-form-two {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
        width: 100%;
        height: 100%;
        margin: 10px 0px;
      }

      .auth-form-input-container {
        width: 100%;
        height: 100%;
        
        margin: 10px 0px;
      }

        .auth-form-label {
          padding-left: 4px;

          font-size: 0.875rem;
          font-weight: 500;
          font-family: 'Libre Baskerville', sans-serif;
          font-family: 'Montserrat', sans-serif;
        }

        .auth-form-input-container input {
          width: 100%;
        }
        

      #auth-form-phonenumber-container {
        display: grid;
        grid-template-columns: 80px auto;
        grid-gap: 0px;

        height: 40px;

        margin-top: 6px;

        overflow: hidden;

        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        border-bottom-left-radius: 2px;
        border-bottom-right-radius: 2px;
        overflow: hidden;

        border-bottom: 2px solid rgb(173, 185, 201);
      }

      #auth-form-phonenumber-container:hover {
        border-bottom: 2px solid rgb(131,0,4);
        border-bottom: 2px solid #4B6B3B;
      }

        #auth-form-phonenumber-container > select {
          width: auto;
          height: 100%;

          text-align: center;
          text-align-last: center;

          border: none;
          background-color: rgb(131,0,4);

          color: #fff;
          font-family: 'Libre Baskerville', sans-serif;
          font-family: 'Montserrat', sans-serif;
          font-size: 12px;
        }

        .auth-form-phonenumber-input {
          display: flex;

          height: 100%;
          width: 100%;

          padding-left: 10px;
          
          outline: none;
          border: none;

          text-overflow: ellipsis;
          overflow: hidden; 

          background-color: transparent;
          background-color: rgba(250,250,250,0.8);

          letter-spacing: 1px;
          font-size: 12px;
          font-family: 'Libre Baskerville', sans-serif;
          font-family: 'Montserrat', sans-serif;
        }
        

    .auth-form-buttons-container {
      display: grid;
      grid-template-columns: auto 120px;
      grid-gap: 10px;
      align-items: center;

      width: 100%;

      padding: 2px;

      margin-top: 30px;
    }
      
      .auth-form-buttons-container > p {
        color: #374258;

        font-size: 12px;
        font-family: 'Libre Baskerville', sans-serif;
        font-family: 'Montserrat', sans-serif;
      }

        .auth-form-buttons-container > p > a {
          cursor: pointer;
          color: rgb(131,0,4);
        }
          
          .auth-form-buttons-container > p > a:hover {
            border-bottom: 1px solid rgb(131,0,4);
            border-bottom: 1px solid #4B6B3B;
          }

      .auth-form-buttons-container > a {
        cursor: pointer;
        color: #374258;

        font-size: 12px;
        font-family: 'Libre Baskerville', sans-serif;
        font-family: 'Montserrat', sans-serif;
      }

      .auth-form-buttons-container > a:hover {
        color: rgb(131,0,4);
        color: #4B6B3B;
      }

      .auth-form-buttons-container  button {
        height: 40px;
        padding: 0px 20px;
      }

    #auth-form-verify-code {
      display: grid;
      grid-template-columns: auto 120px;
      grid-gap: 10px;

      width: 100%;
    }

      #auth-form-verify-code > input[type=numeric] {
        height: 40px;
        width: 100%;
        padding-left: 14px;

        text-overflow: ellipsis;
        overflow: hidden; 

        background-color: rgba(250,250,250,0.8);

        font-family: 'Libre Baskerville', sans-serif;
        font-family: 'Montserrat', sans-serif;
        font-size: 12px;
      }
      
        #auth-form-verify-code > input[type=numeric]:hover {
          border-bottom: 2px solid rgb(131,0,4);
          border-bottom: 2px solid #4B6B3B;
        }

      #auth-form-verify-code > button {
        cursor: pointer;
        height: 100%;
        width: 120px;

        border: 2px solid rgb(131,0,4);
        border-radius: 4px;
        background-color: transparent;

        text-align: center;
        color: rgb(131,0,4);
        font-size: 13px;
        font-family: 'Libre Baskerville', sans-serif;
        font-family: 'Montserrat', sans-serif;
      }

        #auth-form-verify-code > button:hover {
          border: 2px solid rgba(131,0,4,0.7);
          border: 2px solid #4B6B3B;
          color: rgba(131,0,4,0.7);
          color: #4B6B3B;
        }


    .auth-form-forgot-password {
      margin-top: 5px;

      color: #374258;

      font-size: 12px;
      font-family: 'Libre Baskerville', sans-serif;
      font-family: 'Montserrat', sans-serif;
    }
    
      .auth-form-forgot-password > a {
        cursor: pointer;
        color: rgb(131,0,4);
      }
        
        .auth-form-forgot-password > a:hover {
          border-bottom: 1px solid rgb(131,0,4);
          border-bottom: 1px solid #4B6B3B;
        }
}