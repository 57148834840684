@media (max-width: 666px)  {
  .footer {
    position: absolute;
    bottom: -74px;
    z-index: 999;
    width: 100vw;
    height: 70px;

    border-top: 2px solid #C767E5;
    background-color: rgb(30,30,30);
  }

    .footer-messages {
      height: 100%;
      padding-left: 16px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
      .footer-messages > p {
        margin: 0;
        margin-bottom: 8px;
        color: #fff;
        font-size: 13px;
        font-weight: 500;
        font-family: 'Menlo', sans-serif;
      }
      .footer-messages > p > span {
        padding: 2px 4px;
        border-radius: 4px;

        color: #5c0f8b;
        font-size: 14px;
        font-weight: 700;

        background-color: rgb(250,250,250);
      }
        .footer-messages > p > a {
          cursor: pointer;
        }
        .footer-messages > p > a:hover {
          color: #00ccbe;
        }

    .footer-logo {
      position: absolute;
      top: 0;
      right: 16px;
      height: 100%;

      display: flex;
      align-items: center;
      color: #fff;
    }
    
      .footer-logo h2 {
        font-size: 14px;
        font-weight: 600;
        font-family: 'PT Sans', sans-serif;
        font-family: 'Menlo', sans-serif;
      }
        
        .footer-logo h2 a span {
          padding: 6px 4px;
          background: #C767E5;
          border-radius: 8px;
        }
      
      .footer-logo > button {
        cursor: pointer;
        height: 100%;
        margin-left: 4px;
        
        display: flex;
        justify-content: flex-start;
        align-items: center;

        outline: none;
        border: none;
        background-color: transparent;

      }
      
        .footer-logo > button > a {
          color: #00ccbe;
          color: #fff;
          font-size: 26px;
          font-family: 'Menlo', sans-serif;
          text-decoration: none;
        }
        
        .footer-logo > button span {
          pointer-events: none;
          margin-right: 4px;
          padding: 4px 6px;
          border-radius: 8px;

          background: rgba(92,15,139,1);
          background: linear-gradient(180deg, rgba(92,15,139,0.7) 25%, rgba(92,15,139,1) 100%);
          
          color: #fff;
        }

        .footer-logo > button:hover {
          color: #ffdc32;
          color: #5c0f8b;
          color: #00ccbe;
        }

          .footer-logo > button:hover span {
            background: rgba(92,15,139,1);
            background: linear-gradient(180deg, rgba(92,15,139,0.7) 25%, rgba(92,15,139,1) 100%);
            background: rgb(0,204,190);
            background: linear-gradient(180deg, rgba(0,204,190,0.7) 25%, rgba(0,204,190,1) 100%);
          }
}

@media (min-width: 666px) and (max-width: 961px) 
{
  .footer {
    position: absolute;
    bottom: -74px;
    z-index: 999;
    width: 100vw;
    height: 70px;

    border-top: 4px solid #D0BDE2;
    background-color: rgb(30,30,30);
  }

    .footer-messages {
      height: 100%;
      margin-left: 8px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
      .footer-messages > p {
        margin: 0;
        margin-bottom: 8px;
        color: #fff;
        font-size: 13px;
        font-weight: 500;
        font-family: 'Menlo', sans-serif;
      }
      .footer-messages > p > span {
        padding: 2px 4px;
        border-radius: 4px;

        color: #5c0f8b;
        font-size: 14px;
        font-weight: 700;

        background-color: rgb(250,250,250);
      }
        .footer-messages > p > a {
          cursor: pointer;
        }
        .footer-messages > p > a:hover {
          color: #00ccbe;
        }

    .footer-logo {
      position: absolute;
      top: 0;
      right: 8px;
      height: 100%;

      display: flex;
      flex-direction: row;
    }
      .footer-logo > p {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
      }
        .footer-logo > p > span {
          margin-top: -14px;
          color: #fff;
          font-size: 12px;
          font-weight: 500;
          font-family: 'PT Sans', sans-serif;
          font-family: 'Menlo', sans-serif;
        }
      
      .footer-logo > button {
        cursor: pointer;
        height: 100%;
        margin-left: 4px;
        
        display: flex;
        justify-content: flex-start;
        align-items: center;

        outline: none;
        border: none;
        background-color: transparent;

        color: #fff;
        font-size: 14px;
        font-family: 'Menlo', sans-serif;
      }
        .footer-logo > button > a {
          color: #00ccbe;
          color: #fff;
          font-size: 16px;
          font-family: 'Menlo', sans-serif;
          text-decoration: none;
        }
        .footer-logo > button span {
          pointer-events: none;
          margin-right: 4px;
          padding: 4px 6px;
          border-radius: 8px;

          background: rgba(92,15,139,1);
          background: linear-gradient(180deg, rgba(92,15,139,0.7) 25%, rgba(92,15,139,1) 100%);
          
          color: #fff;
        }

        .footer-logo > button:hover {
          color: #ffdc32;
          color: #5c0f8b;
          color: #00ccbe;
        }

          .footer-logo > button:hover span {
            background: rgba(92,15,139,1);
            background: linear-gradient(180deg, rgba(92,15,139,0.7) 25%, rgba(92,15,139,1) 100%);
            background: rgb(0,204,190);
            background: linear-gradient(180deg, rgba(0,204,190,0.7) 25%, rgba(0,204,190,1) 100%);
          }
}

@media (min-width: 961px)  {
  .footer {
    position: relative;
    z-index: 100;
    width: 100vw;
    height: 70px;

    border-top: 4px solid #D0BDE2;
    background-color: rgb(30,30,30);
  }

    .footer-messages {
      height: 100%;
      margin-left: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

      .footer-messages > p {
        margin: 0;
        margin-bottom: 8px;
        color: #fff;
        font-size: 13px;
        font-weight: 500;
        font-family: 'Menlo', sans-serif;
      }
      
      .footer-messages > p > span {
        padding: 2px 4px;
        border-radius: 4px;

        color: #5c0f8b;
        font-size: 14px;
        font-weight: 700;

        background-color: rgb(250,250,250);
      }
      
        .footer-messages > p > a {
          cursor: pointer;
        }
        .footer-messages > p > a:hover {
          color: #00ccbe;
        }


    .footer-logo {
      position: absolute;
      top: 0;
      right: 20px;
      height: 100%;

      display: flex;
      align-items: center;
      color: #fff;
    }
    
      .footer-logo h2 {
        font-size: 14px;
        font-weight: 600;
        font-family: 'PT Sans', sans-serif;
        font-family: 'Menlo', sans-serif;
      }
        
        .footer-logo h2 a span {
          padding: 6px 4px;
          background: #C767E5;
          border-radius: 8px;
        }
      
      .footer-logo > button {
        cursor: pointer;
        height: 100%;
        margin-left: 4px;
        
        display: flex;
        justify-content: flex-start;
        align-items: center;

        outline: none;
        border: none;
        background-color: transparent;

      }
      
        .footer-logo > button > a {
          color: #00ccbe;
          color: #fff;
          font-size: 26px;
          font-family: 'Menlo', sans-serif;
          text-decoration: none;
        }
        
        .footer-logo > button span {
          pointer-events: none;
          margin-right: 4px;
          padding: 4px 6px;
          border-radius: 8px;

          background: rgba(92,15,139,1);
          background: linear-gradient(180deg, rgba(92,15,139,0.7) 25%, rgba(92,15,139,1) 100%);
          
          color: #fff;
        }

        .footer-logo > button:hover {
          color: #ffdc32;
          color: #5c0f8b;
          color: #00ccbe;
        }

          .footer-logo > button:hover span {
            background: rgba(92,15,139,1);
            background: linear-gradient(180deg, rgba(92,15,139,0.7) 25%, rgba(92,15,139,1) 100%);
            background: rgb(0,204,190);
            background: linear-gradient(180deg, rgba(0,204,190,0.7) 25%, rgba(0,204,190,1) 100%);
          }
}