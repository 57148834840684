@media (max-width: 666px) {
	.select-p-add {
		position: relative;
		width: 100%;
	}

	.select-p-add > input {
		width: 100%;
	}
	.select-p-add > textarea {
		width: 100%;
		min-height: 200px;
	}

	.select-p-add-items-none {
		display: none;
	}

	.select-p-add-items {
		position: absolute;
		z-index: 999;
		left: 0;
		top: 100%;
		width: 100%;
		max-height: 140px;
		overflow: scroll;

		border-radius: 8px;
		background-color: rgb(250,250,250);
		box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.2);
	}

	.select-p-add-item {
		cursor: default;

		display: flex;
		align-items: center;
		justify-content: flex-start;

		height: 36px;
		width: 100%;
		padding: 0px 8px;

		list-style-type: none;
		border: none;
		outline: none;

		background-color: transparent;

		color: #000;
	  font-size: 0.875rem;
	  font-family: 'Nunito', sans-serif;        
	  
	  overflow: hidden;
	  text-overflow: ellipsis;
	  white-space: nowrap;
	}
		.select-p-add-item-hover {
			cursor: default;

			display: flex;
			align-items: center;
			justify-content: center;

			height: 32px;
			width: 100%;
			padding: 0px 10px;

			list-style-type: none;
			border: none;
			outline: none;

			background-color: #D0BDE2;

			color: #000;
		  font-size: 14px;
		  font-family: 'Nunito', sans-serif;        
		  
		  overflow: hidden;
		  text-overflow: ellipsis;
		  white-space: nowrap;

		}
			.select-p-add-item.active {
				outline: none;
				background-color: #adbf5a;
			}
}


@media (min-width: 666px) and (max-width: 961px) 
{
	.select-p-add {
		position: relative;
		width: 100%;
	}

	.selected-p-add {
		width: calc(100% - 10px);
		height: 30px;

		padding-left: 6px;

		border: none;
		outline: 1px solid #D0BDE2;
		border-radius: 4px;

		background-color: rgb(240,240,240);

		color: #000;
	  font-size: 16px;
	  font-family: 'PT Sans', sans-serif;
	  font-family: 'Nunito', sans-serif;

	  -webkit-box-shadow: 0px 1px 1px 1px rgba(50,50,50,0.7);
	  -moz-box-shadow: 0px 1px 1px 1px rgba(50,50,50,0.7);
	  box-shadow: 0px 1px 1px 1px rgba(50,50,50,0.7);
	}

		.selected-p-add:active, 
		.selected-p-add:hover, 
		.selected-p-add:focus {
			transition-duration: 0.1s;
			outline: 1px solid #C767E5;

			background-color: #fff;
		}

	.select-p-add-items-none {
		display: none;
	}

	.select-p-add-items {
		position: absolute;
		z-index: 999;
		left: 50%;
		bottom: 34px;
		transform: translateX(-50%);
		padding: 0;
		
		width: 110%;
		max-height: 140px;

		border: 2px solid #D0BDE2;
		border-radius: 10px;

		background-color: rgb(250,250,250);
		overflow: scroll;

		-webkit-box-shadow: 0px 1px 2px 1px rgba(50,50,50,0.7);
	  -moz-box-shadow: 0px 1px 2px 1px rgba(50,50,50,0.7);
	  box-shadow: 0px 1px 2px 1px rgba(50,50,50,0.7);
	}

	.select-p-add-item {
		cursor: default;

		display: flex;
		align-items: center;
		justify-content: center;

		height: 32px;
		width: 100%;
		padding: 0px 10px;

		list-style-type: none;
		border: none;
		outline: none;

		background-color: transparent;

		color: #000;
	  font-size: 14px;
	  font-family: 'Nunito', sans-serif;        
	  
	  overflow: hidden;
	  text-overflow: ellipsis;
	  white-space: nowrap;
	}
		.select-p-add-item-hover {
			cursor: default;

			display: flex;
			align-items: center;
			justify-content: center;

			height: 32px;
			width: 100%;
			padding: 0px 10px;

			list-style-type: none;
			border: none;
			outline: none;

			background-color: #D0BDE2;

			color: #000;
		  font-size: 14px;
		  font-family: 'Nunito', sans-serif;        
		  
		  overflow: hidden;
		  text-overflow: ellipsis;
		  white-space: nowrap;

		}
			.select-p-add-item.active {
				outline: none;
				background-color: #adbf5a;
			}
}

@media (min-width: 961px)  {
	.select-p-add {
		position: relative;
		width: 100%;
	}

	.select-p-add > input {
		width: 100%;
	}
	.select-p-add > textarea {
		width: 100%;
		min-height: 200px;
	}

	.select-p-add-items-none {
		display: none;
	}

	.select-p-add-items {
		position: absolute;
		z-index: 999;
		left: 0;
		top: 100%;
		margin-top: 6px;
		width: 100%;
		max-height: 140px;
		overflow: auto;

		border-radius: 8px;
		background-color: rgb(250,250,250);
		box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.2);
	}

	.select-p-add-item {
		cursor: default;
		height: 36px;
		width: 100%;
		padding: 0px 8px;

		display: flex;
		align-items: center;
		justify-content: flex-start;

		list-style-type: none;
		border: none;
		outline: none;

		background-color: transparent;

		color: #000;
	  font-size: 0.875rem;
	  font-family: 'Nunito', sans-serif;        
	  
	  overflow: hidden;
	  text-overflow: ellipsis;
	  white-space: nowrap;
	}
		.select-p-add-item-hover {
			cursor: default;

			display: flex;
			align-items: center;
			justify-content: center;

			height: 32px;
			width: 100%;
			padding: 0px 10px;

			list-style-type: none;
			border: none;
			outline: none;

			background-color: #D0BDE2;

			color: #000;
		  font-size: 14px;
		  font-family: 'Nunito', sans-serif;        
		  
		  overflow: hidden;
		  text-overflow: ellipsis;
		  white-space: nowrap;

		}
			.select-p-add-item.active {
				outline: none;
				background-color: #adbf5a;
			}
}