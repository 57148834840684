@media (max-width: 666px) {
  .filters {
    height: 100%;
    width: 100%;
    padding: 20px;
    overflow: auto;
    border-radius: inherit;
  }

  .filters-action {
    display: flex;
    justify-content: center;
    align-items: center;
  }
    .filters-action button {
      height: 40px;
      padding: 0px 20px;
      font-weight: 600;
    }

  .filters-section {
    padding: 20px 0px;
    margin-bottom: 0px;
    padding-bottom: 10px;
    
    border-bottom: 1px solid #ccc;
  }
    .filters-section > h4 {      
      color: #46005D;
      margin-bottom: 8px;
    }
    
    .filters-section-input {
      position: relative;
      display: flex;
      flex-direction: row;
    }
      .filters-section-input input {
        width: 100%;
      }

    .filters-units {
      position: relative;
      margin-top: 8px;
    }
      .filters-unit {
        margin-bottom: 8px;
        
        display: block;
        
        outline: none;
        border: none;
        
        text-align: left;
        color: #222;
      }
        .filters-unit i {
          margin-right: 4px;
          color: #D0BDE2;
        }
        .filters-unit:hover i {
          color: #D0BDE2aa;
        }
        .filters-unit.active i {
          color: #C767E5;
        }
    
    .filters-times {
      transition-duration: 0.3s;
      position: absolute;
      top: 50%;
      right: 8px;
      transform: translateY(-50%);
      height: 24px;
      width: 24px;

      display: flex;
      align-items: center;
      justify-content: center;

      outline: none;
      border: none;
      border-radius: 50px;
      background-color: #222222;
    }
      .filters-times:hover {
        background-color: #222222aa;
      }
      .filters-times:disabled {
        pointer-events: none;
        background-color: #cccccc;
      }
      .filters-times > i {
        pointer-events: none;
        color: #fff;
        font-size: 11px;
      }
}


@media (min-width: 666px) and (max-width: 961px) 
{

  .filters-container {
    margin: 8px;
    margin-bottom: 4px;
    height: 50px;
    
    border: 2px solid #D0BDE2;
    border-radius: 8px;
    
    background-color: rgb(250,250,250);
    background-color: #fff;
    
    overflow-y: hidden;
  }
    .filters-container.active {
      height: calc(100% - 26px);
      overflow-y: auto;
    }

  .filters-action-container {
    height: 50px;
    padding: 0px 8px;

    display: grid;
    grid-template-columns: auto 30px;
    grid-gap: 8px;
    align-items: center;
  }

  .filters-action {
    height: 40px;
    margin-top: -2px;
    display: flex;
    align-items: center;
    justify-content: center;

    border: 3px solid #C767E5;
    outline: none;
    border-radius: 25px;

    background-color: #C767E5;

    color: #000;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    font-family: 'Source Code Pro', monospace;
    font-family: 'Nunito', sans-serif;

    -webkit-box-shadow: -1px 1px 2px 1px rgba(50,50,50,0.7);
    -moz-box-shadow: -1px 1px 2px 1px rgba(50,50,50,0.7);
    box-shadow: -1px 1px 2px 1px rgba(50,50,50,0.7);
  }

  .filters-items-menu {
    height: 30px;
    width: 30px;
    
    border: 2px solid #C767E5;
    border-radius: 50px;
    background-color: #fff;
  }

  .filters-section {
    padding: 0px 8px;
    margin: 0px 8px;
    margin-bottom: 0px;
    padding-bottom: 10px;
    
    border-bottom: 1px solid #ccc;
  }

    .filters-section > h2 {
      display: flex;
      align-items: center;
      
      padding-left: 10px;

      color: #46005D;
      font-size: 20px;
      font-weight: bold;
      font-family: 'PT Sans', sans-serif;
    }
    
    .filters-section-input {
      position: relative;
      display: flex;
      flex-direction: row;
    }

    .filters-section-input-unit {
      position: relative;
      display: flex;
      flex-direction: row;

      overflow-x: scroll;
    }
      .filters-section-input-unit::-webkit-scrollbar {
        width: 4px;
        height: 8px;
      }
      .filters-section-input-unit::-webkit-scrollbar-thumb {
        margin-bottom: -10px;
        border-radius: 10px;
        background: #000;
        background: rgba(50,50,50,0.8);
      }
      .filters-section-input > input {
        width: 100%;
        padding: 4px 10px;
        
        border: none;
        outline: none;
        border: 2px solid #ccc;
        border-radius: 30px;

        background-color: transparent;
        
        color: #000;
        font-size: 16px;
        font-family: 'Nunito', sans-serif;
      }
      
        .filters-section-input > input:focus, 
        .filters-section-input > input:hover {
          border: 2px solid #D0BDE2;
          background-color: transparent;
        }

    .filters-section-option, .filters-section-option:focus {
      padding: 4px 10px;
      margin: 0px 2px;
      margin-bottom: 4px;
      
      border: none;
      outline: none;

      border: 3px solid #D0BDE2;
      border-radius: 4px;

      background-color: rgb(250,250,250);
      
      color: #adbf5a;
      color: #000;
      font-size: 16px;
      font-family: 'Nunito', sans-serif;
    }
    
      .filters-section-option:hover {
        border: 3px solid #D0BDE2;
        background-color: #fff;
      }

      .filters-section-option.active {
        color: #fff;
        background-color: #adbf5a;
      }
    
    
    
    .filters-section-times, .filters-section-times:focus, .filters-section-times:active {
      cursor: default;

      position: absolute;
      top: 50%;
      right: 4px;
      transform: translateY(-50%);

      display: flex;
      align-items: center;
      justify-content: center;

      height: 16px;
      width: 16px;

      outline: none;
      border: none;
      border-radius: 50px;
      background-color: rgb(80,80,80);
    }

      .filters-section-times:hover {
        cursor: default;
        background-color: rgba(80,80,80,0.8);
      }

      .filters-section-times > span {
        cursor: default;
        pointer-events: none;
        color: #fff;
        font-size: 8px;
      }
}

@media (min-width: 961px)  {
  .filters {
    height: 100%;
    width: 100%;
    padding: 20px;
    overflow: auto;
    border-radius: inherit;
  }

  .filters-action {
    display: flex;
    justify-content: center;
    align-items: center;
  }
    .filters-action button {
      height: 40px;
      padding: 0px 20px;
      font-weight: 600;
    }

  .filters-section {
    padding: 20px 0px;
    margin-bottom: 0px;
    padding-bottom: 10px;
    
    border-bottom: 1px solid #ccc;
  }
    .filters-section > h4 {      
      color: #46005D;
      margin-bottom: 8px;
    }
    
    .filters-section-input {
      position: relative;
      display: flex;
      flex-direction: row;
    }
      .filters-section-input input {
        width: 100%;
      }

    .filters-units {
      position: relative;
      margin-top: 8px;
    }
      .filters-unit {
        margin-bottom: 8px;
        
        display: block;
        
        outline: none;
        border: none;
        
        text-align: left;
        color: #222;
      }
        .filters-unit i {
          margin-right: 4px;
          color: #D0BDE2;
        }
        .filters-unit:hover i {
          color: #D0BDE2aa;
        }
        .filters-unit.active i {
          color: #C767E5;
        }
    
    .filters-times {
      transition-duration: 0.3s;
      position: absolute;
      top: 50%;
      right: 8px;
      transform: translateY(-50%);
      height: 24px;
      width: 24px;

      display: flex;
      align-items: center;
      justify-content: center;

      outline: none;
      border: none;
      border-radius: 50px;
      background-color: #222222;
    }
      .filters-times:hover {
        background-color: #222222aa;
      }
      .filters-times:disabled {
        pointer-events: none;
        background-color: #cccccc;
      }
      .filters-times > i {
        pointer-events: none;
        color: #fff;
        font-size: 11px;
      }
}