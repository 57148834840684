@media (max-width: 666px) {
  .navbar {
    position: relative;
    height: 70px;
    width: 100vw;

    border-bottom: 4px solid #D0BDE2;
    border-bottom: 2px solid #C767E5;

    background-color: #ccc;
    background-color: #fff;
    background-color: #F0F0F0;
  }

    .navbar-logo {
      position: absolute;
      top: 50%;
      left: 0px;
      transform: translateY(-50%);
      margin-left: 8px;

      background-color: transparent;
    }
      .navbar-logo > img {
        height: 50px;
      }

    .navbar-session {
      float: right;
      margin-right: 16px;
      height: 100%;

      display: flex;
      align-items: center;
    }
      .navbar-session > a {
        height: 40px;
        width: 40px;
      }
      .navbar-session > button {
        cursor: pointer;
        
        outline: none;
        border: none;
        background-color: transparent;
        
        color: #fff;
        color: #46005D;
        font-size: 1rem;
      }


    .navbar-submenu {
      z-index: 999;
      position: absolute;
      width: 100%;
      top: 272px;
      left: 0px;
      padding-top: 50px;
      
      display: none;
      flex-direction: column;

      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
      background-color: rgb(50,50,50);
      background-color: #46005D;
      background-color: #F0F0F0;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);

      overflow: hidden;
    }
      .navbar-submenu > button {
        width: 100%;
        height: 50px;
        padding: 0px 10px;
        border: none;
        outline: none;

        display: flex;
        align-items: center;
        justify-content: flex-start;

        background-color: transparent;

        text-align: left;
        color: #fff;
        font-size: 20px;
        font-family: 'Source Code Pro', monospace;
        font-family: 'Everett', sans-serif;
        font-family: 'Space Mono', sans-serif;
      }
        .navbar-submenu > button > span {
          margin-right: 8px;
          color: #fff;
        }

        .navbar-submenu > button:hover {
          background-color: rgb(100,100,100);
        }
}


@media (min-width: 666px) and (max-width: 961px) 
{
  .navbar {
    position: relative;
    height: 70px;
    width: 100vw;

    border-bottom: 4px solid #D0BDE2;
    border-bottom: 2px solid #C767E5;

    background-color: #ccc;
    background-color: #fff;
    background-color: #F0F0F0;
  }

    .navbar-logo {
      position: absolute;
      top: 50%;
      left: 0px;
      transform: translateY(-50%);

      padding: 2px 8px;

      display: flex;
      align-items: center;
      justify-content: center;

      border-radius: 50px;
      background-color: #fff;
      background-color: transparent;
    }
    
      .navbar-logo > img {
        height: 50px;
      }

    .navbar-session {
      float: right;
      margin-right: 8px;
      height: 100%;

      display: flex;
      align-items: center;
    }
    
    .navbar-session > button {
      cursor: pointer;
      
      border: none;
      outline: none;
      
      color: #fff;
      color: #46005D;
      font-size: 16px;
      font-weight: 500;
      font-family: 'Source Code Pro', monospace;
      font-family: 'Space Mono', sans-serif;
      font-family: 'Everett', sans-serif;

      background-color: transparent;
    }
      .navbar-session > button > span {
        font-size: 14px;
        margin-left: 8px;
      }


    .navbar-submenu {
      z-index: 999;
      position: absolute;
      width: 100%;
      top: 232px;
      left: 0px;
      padding-top: 50px;
      
      display: flex;
      flex-direction: column;

      background-color: rgb(50,50,50);
      background-color: #46005D;

      overflow: hidden;
    }
      .navbar-submenu > button {
        width: 100%;
        height: 40px;
        padding: 0px 10px;
        border: none;
        outline: none;

        display: flex;
        align-items: center;
        justify-content: flex-start;

        background-color: transparent;

        text-align: left;
        color: #fff;
        font-size: 14px;
        font-family: 'Source Code Pro', monospace;
        font-family: 'Everett', sans-serif;
        font-family: 'Space Mono', sans-serif;
      }
        .navbar-submenu > button > span {
          margin-right: 8px;
          color: #fff;
        }

        .navbar-submenu > button:hover {
          background-color: rgb(100,100,100);
        }
}

@media (min-width: 961px)  {
  .navbar {
    position: relative;
    height: 70px;
    width: 100vw;

    border-bottom: 4px solid #D0BDE2;

    background-color: #ccc;
    background-color: rgb(240,240,240);
  }

    .navbar-logo {
      position: absolute;
      top: 50%;
      left: 20px;
      transform: translateY(-50%);

      display: flex;
      align-items: center;
      justify-content: center;

      background-color: transparent;
    }
    
      .navbar-logo > img {
        height: 50px;
      }

    .navbar-session {
      position: absolute;
      right: 20px;
      height: 100%;

      display: flex;
      align-items: center;
    }
    
    .navbar-session > button {
      cursor: pointer;
      
      border: none;
      outline: none;
      
      color: #fff;
      color: #46005D;
      font-size: 16px;
      font-weight: 500;
      font-family: 'Source Code Pro', monospace;
      font-family: 'Space Mono', sans-serif;
      font-family: 'Everett', sans-serif;

      background-color: transparent;
    }

      .navbar-session > button:hover {
        color: #C767E5;
      }

      .navbar-session > button > span {
        font-size: 14px;
        margin-left: 4px;
      }


    .navbar-submenu {
      z-index: 999;
      position: absolute;
      top: 44px;
      right: -10px;
      
      display: flex;
      flex-direction: column;

      border-radius: 10px;

      background-color: rgb(50,50,50);

      overflow: hidden;
    }
    
      .navbar-submenu > button {
        width: 200px;
        padding: 10px 10px;
        border: none;
        outline: none;

        background-color: transparent;
        text-align: left;
        color: #fff;
        font-size: 14px;
        font-family: 'Source Code Pro', monospace;
        font-family: 'Everett', sans-serif;
        font-family: 'Space Mono', sans-serif;
      }
      
      .navbar-submenu > button > span {
        margin-right: 10px;
        color: #fff;
      }

        .navbar-submenu > button:hover {
          background-color: rgb(100,100,100);
        }
}