
@media (max-width: 666px)  {

}

@media (min-width: 666px) and (max-width: 961px) 
{
}

@media (min-width: 961px)  {
  .data {
    height: 100%;
    width: 100%;
    padding: 20px;
    overflow: auto;

    display: grid;
    grid-template-columns: repeat(6,1fr);
    grid-auto-rows: 12rem;
    grid-gap: 20px;
  }

    .widgets {
        grid-column: 1/7;
        grid-row: 1;
        display: flex;
        gap: 20px;
    }
        .widget{
            height: 100%;
            padding: 10px;

            display: flex;
            justify-content: space-between;
            flex: 1;
            
            border-radius: 16px;
            box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.1);
        }
            .left, .right{
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
        
            .title{
                font-weight: bold;
                font-size: 14px;
                color: rgb(160, 160, 160);
            }
        
            .counter{
                font-style: 20px;
                font-weight: 300;
            }
        
            .link{
                width: max-content;
                font-size: 12px;
                border-bottom: 1px solid gray;
                color: black;
            }
                .link:hover{
                    background-color: #374258;
                    color: #cea000;
                }

                .link:active {
                    color: black;
                    background-color: white;
                }
            
                .percentage {
                    display: flex;
                    align-items: center;
                    font-style: 14px;
                }
                    .percentage.positive{
                        color: green;
                    }
            
                    .percentage.negative{
                        color:red;
                    }
            
                .icon{
                    font-size: 18px;
                    padding: 5px;
                    border-radius: 5px;
                    align-self: flex-end;
                }

    .charts {
        grid-column: 1/7;
        grid-row: 2/5;
    }
}
